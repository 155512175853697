import React, { useState } from 'react';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import DashboardCard from '../../components/card/DashboardCard';
import { GET_STUDENT, UPDATE_STUDENT, getStudent, updateStudent } from '../../faunadb/queries/student';
import { CardButton } from '../../styles/common/Card';
import { toast } from 'react-toastify';
import { useFaunaContext } from '../../providers/FaunaProvider';
import Logging from '../logging/Loggings';
import Order from '../../components/order/Order';
import { Button, Table } from 'rsuite';
import { getWeekday } from '../../faunadb/helpers/utils';
import { useHistory } from 'react-router-dom';
import {useQuery as useReactQuery, useMutation as useReactMutation, useQueryClient} from 'react-query';

const Row = styled.div`
  display: flex;
  width: 100%;
  color: white;
  margin: 5px 0px;
`;

const Col = styled.div`
  width: 50%;
  text-align: center;
`;

const StudentDetail = (props) => {
  const history = useHistory();
  const studentId = props.match.params.id;
  const [credit, setCredit] = useState(0);
  const [catchUpCreditTier0, setCatchUpCreditTier0] = useState(0);
  const [catchUpCreditTier1, setCatchUpCreditTier1] = useState(0);
  const [catchUpCreditTier2, setCatchUpCreditTier2] = useState(0);
  
  const { queries, client } = useFaunaContext();
  const queryClient = useQueryClient();
  const {data, loading} = useReactQuery(['student', studentId], async() => await getStudent(client, studentId),{
    onSuccess: (data) => {
      setVip(data.vip);
    }
  })
  const [vip, setVip] = useState(data?.vip || false);

  const updateStudentMutation = useReactMutation(student=>updateStudent(client,student),{
    onSuccess: () => {
      toast.success('Student Updated.');
      if (credit !== 0) {
        queries.log.logging(
          client,
          'Teacher',
          sessionStorage.getItem('admin_id'),
          'Student',
          data._id,
          `Top up $${parseInt(credit)}`
        );
      }
      if (catchUpCreditTier0 !== 0) {
        queries.log.logging(
          client,
          'Teacher',
          sessionStorage.getItem('admin_id'),
          'Student',
          data._id,
          `Add Bronze catch up credit by ${parseInt(
            catchUpCreditTier0
          )}`
        );
      }
      if (catchUpCreditTier1 !== 0) {
        queries.log.logging(
          client,
          'Teacher',
          sessionStorage.getItem('admin_id'),
          'Student',
          data._id,
          `Add Silver catch up credit by ${parseInt(
            catchUpCreditTier1
          )}`
        );
      }
      if (catchUpCreditTier2 !== 0) {
        queries.log.logging(
          client,
          'Teacher',
          sessionStorage.getItem('admin_id'),
          'Student',
          data._id,
          `Add Gold catch up creditby ${parseInt(
            catchUpCreditTier2
          )}`
        );
      }
      setCredit(0);
      setCatchUpCreditTier0(0);
      setCatchUpCreditTier1(0);
      setCatchUpCreditTier2(0);
      queryClient.invalidateQueries('student');
    },
    onError: (error) => {
      toast.error('Student Update Fail!');
    }
  });

  return (
    <>
      {!loading && data && (
        <>
          <DashboardCard title={`${data.email}`}>
            <Row>
              <Col>Student ID</Col>
              <Col>{studentId}</Col>
            </Row>
            <Row>
              <Col>email</Col>
              <Col>{data.email}</Col>
            </Row>
            <Row>
              <Col>name</Col>
              <Col>{data.name || '-'}</Col>
            </Row>
            <Row>
              <Col>phone number</Col>
              <Col>{data.phone_number || '-'}</Col>
            </Row>
            <Row>
              <Col>credit</Col>

              <Col>
                <Col style={{ width: '100%' }}>
                  <Col style={{ width: '100%' }}>
                    {data.credit}
                  </Col>
                  <Col style={{ width: '100%' }}>
                    <input
                      style={{ color: 'black' }}
                      type="number"
                      step={1}
                      value={credit}
                      onChange={({ target }) => {
                        setCredit(target.value);
                      }}
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>Bronze Catch up credit</Col>

              <Col>
                <Col style={{ width: '100%' }}>
                  <Col style={{ width: '100%' }}>
                    {data.catch_up_credit_tier_0 || 0}
                  </Col>
                  <Col style={{ width: '100%' }}>
                    <input
                      style={{ color: 'black' }}
                      type="number"
                      step={1}
                      value={catchUpCreditTier0}
                      onChange={({ target }) => {
                        setCatchUpCreditTier0(target.value);
                      }}
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>Silver Catch up credit</Col>

              <Col>
                <Col style={{ width: '100%' }}>
                  <Col style={{ width: '100%' }}>
                    {data.catch_up_credit_tier_1 || 0}
                  </Col>
                  <Col style={{ width: '100%' }}>
                    <input
                      style={{ color: 'black' }}
                      type="number"
                      step={1}
                      value={catchUpCreditTier1}
                      onChange={({ target }) => {
                        setCatchUpCreditTier1(target.value);
                      }}
                    />
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col>Gold Catch up credit</Col>

              <Col>
                <Col style={{ width: '100%' }}>
                  <Col style={{ width: '100%' }}>
                    {data.catch_up_credit_tier_2 || 0}
                  </Col>
                  <Col style={{ width: '100%' }}>
                    <input
                      style={{ color: 'black' }}
                      type="number"
                      step={1}
                      value={catchUpCreditTier2}
                      onChange={({ target }) => {
                        setCatchUpCreditTier2(target.value);
                      }}
                    />
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col>Vip</Col>
              <Col>
                <Toggle
                  defaultChecked={data.vip}
                  onChange={() => setVip(!vip)}
                  value={String(vip)}
                />
              </Col>
            </Row>
            <Row>
              <Col>VVip</Col>
              <Col>{data?.vvip ? 'VVIP' : ''}</Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <CardButton
                style={{ maxWidth: '300px' }}
                onClick={() => {
                  updateStudentMutation.mutate({
                    ...data,
                    id: data._id,
                    credit: data.credit + parseInt(credit),
                    catch_up_credit_tier_0:
                      data.catch_up_credit_tier_0 + parseInt(catchUpCreditTier0),
                    catch_up_credit_tier_1:
                      data.catch_up_credit_tier_1 + parseInt(catchUpCreditTier1),
                    catch_up_credit_tier_2:
                    data.catch_up_credit_tier_2 + parseInt(catchUpCreditTier2),
                    vip: vip
                  })
                }}
                // disabled={updateStudentLoading}
              >
                {updateStudentMutation.isLoading ? 'Updating' : 'Update'}
              </CardButton>
            </Row>
          </DashboardCard>
          <DashboardCard title="Enrolled Course">
            <Table
              data={data.courses.data}
              autoHeight
              virtualized
              style={{ width: '100%' }}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Term</Table.HeaderCell>
                <Table.Cell dataKey="term.name" />
              </Table.Column>

              {/* <Table.Column flexGrow={1}>
                <Table.HeaderCell>Period</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) =>
                    `${rowData.term.start_date} - ${rowData.term.end_date}`
                  }
                </Table.Cell>
              </Table.Column> */}
              <Table.Column>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.Cell dataKey="name" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Teacher</Table.HeaderCell>
                <Table.Cell>{(rowData) => rowData.teacher.name}</Table.Cell>
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Weekday</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => getWeekday(rowData.weekday)}
                </Table.Cell>
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Start at</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => getWeekday(rowData.start_at)}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1} fixed="right">
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => {
                    return (
                      <span>
                        <Button
                          appearance="link"
                          onClick={() => history.push(`/course/${rowData._id}`)}
                        >
                          {' '}
                          View{' '}
                        </Button>
                      </span>
                    );
                  }}
                </Table.Cell>
              </Table.Column>
            </Table>
          </DashboardCard>
          <DashboardCard title="Logging">
            <Logging type="Student" id={studentId} />
          </DashboardCard>
          <DashboardCard title="Orders">
            <Order studentId={studentId} />
          </DashboardCard>
        </>
      )}
    </>
  );
};

export default StudentDetail;
