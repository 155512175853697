import { signal } from '@preact/signals-react';
import { useEffect, useRef } from 'react';
import { Button, Checkbox, Container, Input, Table } from 'rsuite';
import DashboardCard from '../../components/card/DashboardCard';
import {
  createNotification,
  deleteNotification,
  getNotifications,
  updateNotificationShow,
} from '../../faunadb/queries/notification';
import { useFaunaContext } from '../../providers/FaunaProvider';
import { toast } from 'react-toastify';

const notificationSignal = signal([]);

export default function NotificationPage() {
  const contentRef = useRef();
  const { client } = useFaunaContext();

  const getNoti = async () => {
    const notifications = await getNotifications(client);
    notificationSignal.value = notifications?.data || [];
  };

  useEffect(() => {
    getNoti();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    createNotification(client, contentRef.current.value)
      .then((response) => {
        if (response) {
          toast.success('Message Created!');
        }
      })
      .catch((err) => console.log(err))
      .finally(() => getNoti());
  };
  console.log(notificationSignal.value);
  return (
    <>
      <DashboardCard title={'Notification'} />
      <Container>
        <form style={{ margin: '2rem 0' }} onSubmit={onSubmit}>
          {/* register your input into the hook by invoking the "register" function */}
          <label>Message</label>
          <Input
            ref={contentRef}
            label="content"
            name="content"
            placeholder="Message Here"
          />
          <Input style={{ maxWidth: '7rem' }} type="submit" />
        </form>
        <Table
          data={notificationSignal.value}
          autoHeight
          wordWrap={'break-word'}
        >
          <Table.Column flexGrow={2}>
            <Table.HeaderCell>Content</Table.HeaderCell>
            <Table.Cell dataKey="data.content" />
          </Table.Column>
          <Table.Column fixed={'right'} flexGrow={1}>
            <Table.HeaderCell>Show?</Table.HeaderCell>
            <Table.Cell>
              {({ data, ref }) => {
                console.log(data, ref.value.id);
                return (
                  <Checkbox
                    checked={data.show}
                    onChange={(v, checked) => {
                      updateNotificationShow(client, ref.value.id, checked)
                        .then((response) => {
                          if (response) {
                            toast.success(
                              checked ? 'Message showed!' : 'Message hidden!'
                            );
                          }
                        })
                        .catch((err) => toast.error(err))
                        .finally(() => getNoti());
                    }}
                  />
                );
              }}
            </Table.Cell>
          </Table.Column>
          <Table.Column fixed={'right'} flexGrow={1}>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.Cell>
              {({ data, ref }) => {
                console.log(data, ref.value.id);
                return (
                  <Button
                    onClick={() => {
                      deleteNotification(client, ref.value.id).finally(() => {
                        getNoti();
                      });
                    }}
                  >
                    Remove
                  </Button>
                );
              }}
            </Table.Cell>
          </Table.Column>
        </Table>
      </Container>
    </>
  );
}
