import { Button, Table } from "rsuite";
import usePerformanceTerm from "../hooks/usePerformanceTerm";
import moment from "moment";
import Swal from "sweetalert2";
import { useMutation, useQueryClient } from "react-query";
import { useFaunaContext } from "../../../providers/FaunaProvider";
import { delete_performance_term } from "../../../faunadb/queries/performance";
import { useHistory } from "react-router-dom";

export default function PerformanceTermTable() {
    const history = useHistory();
    const { data, isLoading } = usePerformanceTerm();
    const { client } = useFaunaContext();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation(delete_performance_term, {
        onSuccess: () => {
            queryClient.invalidateQueries('performance-term');
        }
    })

    const getData = () => {
        if (!data) return []
        return data.sort((a, b) => (new Date(b.data.application_start_date.value)).getTime() - (new Date(a.data.application_start_date.value)).getTime())
    }

    return <>
        <Table
            virtualized
            style={{ width: '100%' }}
            autoHeight
            data={getData()}
            loading={isLoading}>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.Cell>{(rowData) => rowData?.data.name}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
                <Table.HeaderCell>Application Start Date</Table.HeaderCell>
                <Table.Cell>{(rowData) => moment(rowData?.data.application_start_date.value).local().format('YYYY-MM-DD HH:mm')}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
                <Table.HeaderCell>Application End Date</Table.HeaderCell>
                <Table.Cell>{(rowData) => moment(rowData?.data.application_end_date.value).local().format('YYYY-MM-DD HH:mm')}</Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell>Show on dashboard</Table.HeaderCell>
                <Table.Cell>{(rowData) => rowData?.data.show_on_dashboard ? 'Yes' : 'No'}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} fixed="right">
                <Table.HeaderCell>Action</Table.HeaderCell>

                <Table.Cell>
                    {(term) => {
                        function handleAction() {
                            Swal.fire({
                                title: 'Do you want to delete this term?',
                                showCancelButton: true,
                                confirmButtonText: 'Delete',
                                denyButtonText: `Don't save`,
                            }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    console.log(term)
                                    deleteMutation.mutate({
                                        client: client,
                                        id: term.ref.value.id
                                    });
                                }
                            });
                        }
                        return (
                            <span>
                                <Button
                                    appearance="link"
                                    onClick={() => { history.push(`/performance-term/${term.ref.value.id}`) }}
                                >
                                    {' '}
                                    View{' '}
                                </Button>{' '}
                                |
                                <Button appearance="link" onClick={handleAction}>
                                    {' '}
                                    Remove{' '}
                                </Button>
                            </span>
                        );
                    }}
                </Table.Cell>
            </Table.Column>
        </Table>
    </>
}