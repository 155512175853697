import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { printErrorMessageFromFQL } from '../../../faunadb/helpers/utils';
import { toast } from 'react-toastify';
import { Button, Pagination, Table } from 'rsuite';

const SearchBox = styled.input`
  margin: 15px auto;
  height: 3em;
  border-radius: 3em;
  border: none;
  padding: 0px 2em;
  min-width: 400px;
  color: black;
  &:focus {
    outline: none;
  }
`;

const StudentList = ({ students, courseId, updateGetCourseCache }) => {
  const history = useHistory();
  const [filterValue, setFilterValue] = useState('');
  const [studentsState, setStudentsState] = useState(students);
  const { queries, client } = useFaunaContext();

  const [limit, setLimit] = React.useState(50);
  const [page, setPage] = React.useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const paginatedData = studentsState.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  useEffect(() => {
    setPage(1);
    setStudentsState(
      students.filter(function (a) {
        console.log(a);
        let tempName = a.name ? a.name : '';
        let tempPhoneNumber = a.phone_number ? a.phone_number : '';
        let tempvip = a.vip ? 'VIP' : 'General';
        let tempvvip = a?.vvip ? 'VVIP' : '';
        return (
          a.email.toLowerCase().includes(filterValue.toLowerCase()) ||
          tempName.toLowerCase().includes(filterValue.toLowerCase()) ||
          tempPhoneNumber.includes(filterValue) ||
          tempvip.toLowerCase().includes(filterValue.toLowerCase()) ||
          tempvvip.toLowerCase().includes(filterValue.toLowerCase())
        );
      })
    );
  }, [filterValue, students]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SearchBox
          placeholder="search by email / name / vip / phone number"
          value={filterValue}
          onChange={({ target }) => {
            setFilterValue(target.value);
          }}
        ></SearchBox>
      </div>

      <div style={{ padding: 20 }}>
        <Pagination
          style={{ justifyContent: 'end' }}
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="xs"
          layout={['pager', '|', 'limit']}
          total={students.length}
          limitOptions={[50, 80, 100, 150]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      <Table
        virtualized
        style={{ width: '100%' }}
        autoHeight
        data={paginatedData}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.Cell dataKey="email" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Phone Number</Table.HeaderCell>
          <Table.Cell dataKey="phone_number" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>VIP</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return rowData?.vip ? 'VIP' : 'General';
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>VVIP</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return rowData?.vvip ? 'VVIP' : '';
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>credit</Table.HeaderCell>
          <Table.Cell dataKey="credit" />
        </Table.Column>
        <Table.Column width={50}>
          <Table.HeaderCell>🥉</Table.HeaderCell>
          <Table.Cell dataKey="catch_up_credit_tier_0" />
        </Table.Column>
        <Table.Column width={50}>
          <Table.HeaderCell>🥈</Table.HeaderCell>
          <Table.Cell dataKey="catch_up_credit_tier_1" />
        </Table.Column>
        <Table.Column width={50}>
          <Table.HeaderCell>🥇</Table.HeaderCell>
          <Table.Cell dataKey="catch_up_credit_tier_2" />
        </Table.Column>

        <Table.Column flexGrow={1} fixed="right">
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.Cell>
            {(student) => {
              return (
                <span>
                  <Button
                    appearance="link"
                    onClick={() => history.push(`/student/${student._id}`)}
                  >
                    View{' '}
                  </Button>{' '}
                </span>
              );
            }}
          </Table.Cell>
        </Table.Column>
        {courseId && (
          <Table.Column flexGrow={1} fixed="right">
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.Cell>
              {(student) => {
                return (
                  <span>
                    <Button
                      appearance="link"
                      onClick={() => {
                        queries.course
                          .DELETE_USER_FROM_COURSE(
                            client,
                            courseId,
                            student._id
                          )
                          .then((response) => {
                            if (response.errors) {
                              printErrorMessageFromFQL(response.errors);
                            } else {
                              toast.success('Student removed from course');
                              queries.log.logging(
                                client,
                                'Student',
                                student._id,
                                'Course',
                                courseId,
                                `out from by admin`
                              );
                              updateGetCourseCache();
                            }
                          });
                      }}
                    >
                      Disable{' '}
                    </Button>{' '}
                  </span>
                );
              }}
            </Table.Cell>
          </Table.Column>
        )}
      </Table>
      {/* <Table
        style={{
          color: 'white',
        }}
      >
        <Thead>
          <Tr>
            <Th>Item No.</Th>
            <Th>Email</Th>
            <Th>Name</Th>
            <Th>Phone number</Th>
            <Th>Credit</Th>
            <Th>Vip</Th>
            <Th></Th>
            {courseId ? <Th></Th> : <></>}
          </Tr>
        </Thead>
        <Tbody>
          {studentsState.length > 0
            ? studentsState.map((student, i) => (
                <Tr
                  key={`${student._id}`}
                  style={{
                    border: 'none',
                    borderBottom: '1px solid white',
                  }}
                >
                  <Td
                    style={{
                      textOverflow: 'ellipsis',
                      overflowWrap: 'unset',
                      overflow: 'hidden',
                      textAlign: 'center',
                    }}
                  >
                    {i + 1}
                  </Td>
                  <Td
                    style={{
                      textOverflow: 'ellipsis',
                      overflowWrap: 'unset',
                      overflow: 'hidden',
                      textAlign: 'center',
                    }}
                  >
                    {student.email}
                  </Td>
                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {student.name ? student.name : '<Name unset>'}
                  </Td>
                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {student.phone_number
                      ? student.phone_number
                      : '<Phone unset>'}
                  </Td>
                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {student.credit}
                  </Td>
                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {student.vip ? 'VIP' : 'General'}
                  </Td>
                  <Td>
                    <CardButton
                      onClick={() => {
                        history.push(`/student/${student._id}`);
                      }}
                    >
                      View
                    </CardButton>
                  </Td>
                  {courseId && (
                    <Td>
                      <CardButton
                        style={{ background: 'red' }}
                        onClick={() => {
                          queries.course
                            .DELETE_USER_FROM_COURSE(
                              client,
                              courseId,
                              student._id
                            )
                            .then((response) => {
                              if (response.errors) {
                                printErrorMessageFromFQL(response.errors);
                              } else {
                                toast.success('Student removed from course');
                                queries.log.logging(
                                  client,
                                  'Student',
                                  student._id,
                                  'Course',
                                  courseId,
                                  `out from by admin`
                                );
                                updateGetCourseCache();
                              }
                            });
                        }}
                      >
                        Disable
                      </CardButton>
                    </Td>
                  )}
                </Tr>
              ))
            : 'No students yet'}
        </Tbody>
      </Table> */}
    </>
  );
};

export default StudentList;
