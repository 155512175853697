import React from "react";
import {
  LoginBackground,
  LoginCard,
  LoginCardBody,
  LoginCardLogo,
  LineBreak,
} from "./styles/LoginStyle";

import LoginForm from "./components/LoginForm";

import LoginLogo from "../../assets/images/login_logo.png";
const Login = () => {
  return (
    <LoginBackground>
      <LoginCard>
        <LoginCardBody>
          <LoginCardLogo src={LoginLogo}></LoginCardLogo>
          <LineBreak />
          <LoginForm></LoginForm>
        </LoginCardBody>
      </LoginCard>
    </LoginBackground>
  );
};

export default Login;
