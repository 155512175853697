import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  height: auto;
  /* background-color: rgba(255, 255, 255, 0.15); */
  /* backdrop-filter: blur(5px); */
  border-radius: 10px;
  padding: 30px;
  max-width: 100%;
  min-width: 150px;
  margin: 10px;
  flex: 1;
`;

const Title = styled.h1`
  color: #fff;
  border-top: 1px solid #c5c5c540;
  border-bottom: 1px solid #c5c5c540;
`;

const DashboardCard = ({ title, children }) => {
  return (
    <>
      <StyledContainer>
        <Title>{title}</Title>
        {children}
      </StyledContainer>
    </>
  );
};

export default DashboardCard;
