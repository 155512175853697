import React from 'react';
import { getTerm, updateTerm } from '../../faunadb/queries/term';
import DashboardCard from '../../components/card/DashboardCard';
import CourseList from './components/CourseList';
import InfoTable from '../../components/table/InfoTable';
import { toast } from 'react-toastify';
import TermSummary from './components/TermSummary';
import {useQuery as useReactQuery, useMutation as useReactMutation, useQueryClient} from 'react-query';
import { useFaunaContext } from '../../providers/FaunaProvider';
const Term = (props) => {
  const termId = props.match.params.id;
  const {client} = useFaunaContext();
  const queryClient = useQueryClient();
  const { data, loading } = useReactQuery(['term',termId], async()=> await getTerm(client,termId));

  const udpateTermMutation = useReactMutation(term=>updateTerm(client, term), {
    onSuccess: (data) => {
      toast.success('Term Updated');
      queryClient.invalidateQueries(['term',termId]);
      queryClient.invalidateQueries('allTerms');
    }
  });

  const updatePublish = (e) => {
    let value = !Boolean(parseInt(e.target.value));
    value = value === true ? 1 : 0;
    udpateTermMutation.mutate({
      ...data,
      status: value,
    });
  };

  return (
    <>
      {!loading && data && (
        <>
          <DashboardCard title={`${data.name}`}>
            <InfoTable
              cols={[
                { Headers: 'Classroom', accessor: 'classroom.name' },
                { Headers: 'Start Date', accessor: 'start_date.value' },
                { Headers: 'End Date', accessor: 'end_date.value' },
                { Headers: 'Break Start Date', accessor: 'break_start_date.value' },
                { Headers: 'Brak End Date', accessor: 'break_end_date.value' },
                {
                  Headers: 'Status',
                  accessor: 'status',
                  onChange: updatePublish,
                },
              ]}
              data={[data]}
            ></InfoTable>
          </DashboardCard>
          <DashboardCard title="Summary">
            <TermSummary termId={termId}></TermSummary>
          </DashboardCard>
          <DashboardCard title="Courses">
            <CourseList term={data}></CourseList>
          </DashboardCard>
        </>
      )}
    </>
  );
};

export default Term;
