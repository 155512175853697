import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
		font-size: 16px;
		box-sizing: border-box;
	}
  *, *:before, *:after {
		padding: 0;
		margin: 0;
		box-sizing: inherit;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
	}
  body {
    font-size: 1rem;
    line-height: 1.8;
    background: #1b1b1b;
  }
`;

export default GlobalStyle;
