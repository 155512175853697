import {
  Collection,
  Create,
  Delete,
  Get,
  Index,
  Lambda,
  Map,
  Match,
  Paginate,
  Ref,
  Update,
  Var,
} from 'faunadb';

export const createNotification = async (client, content) => {
  try {
    let response = await client.query(
      Create(Collection('Notification'), {
        data: { content: content, show: false },
      })
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

export const getNotifications = async (client) => {
  try {
    let response = await client.query(
      Map(
        Paginate(Match(Index('allNotification')), { size: 9999 }),
        Lambda('noti', Get(Var('noti')))
      )
    );
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
};

export const updateNotificationShow = async (client, id, show) => {
  try {
    let response = await client.query(
      Update(Ref(Collection('Notification'), id), {
        data: { show: show },
      })
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

export const deleteNotification = async (client, id) => {
  try {
    let response = await client.query(
      Delete(Ref(Collection('Notification'), id))
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};
