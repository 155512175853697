import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'rsuite';
import { Wrapper } from '../../../styles/common/Wrapper';

const ClassList = ({ classes }) => {
  const history = useHistory();
  console.log(classes);
  return (
    <Wrapper>
      <Table data={classes} autoHeight style={{ width: '100%' }}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell dataKey="date"> Date </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => moment(rowData.date.value).local().format('MMM Do')}
          </Table.Cell>
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell dataKey="date"> Start </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => moment(rowData.date.value).local().format('HH:mm')}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell dataKey="date"> End </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => moment(rowData.end_date.value).local().format('HH:mm')}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell dataKey="date"> Attendees </Table.HeaderCell>
          <Table.Cell>{(rowData) => rowData.attendees.data.length}</Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} fixed="right">
          <Table.HeaderCell>Action</Table.HeaderCell>

          <Table.Cell>
            {(rowData) => {
              return (
                <span>
                  <Button
                    appearance="link"
                    onClick={() => history.push(`/class/${rowData._id}`)}
                  >
                    {' '}
                    View{' '}
                  </Button>{' '}
                </span>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Wrapper>
  );
};

export default ClassList;
