import gql from 'graphql-tag';
import faunadb, { Create, TimeSubtract } from 'faunadb';
import { Select } from '../helpers/utils';
const q = faunadb.query;
const {
  Call,
  Update,
  Ref,
  Collection,
  Map,
  Paginate,
  Range,
  Match,
  Index,
  Get,
  Now,
  TimeAdd,
  Lambda,
  Var,
  CurrentIdentity,
  If,
  Let,
  Do,
  Abort,
  Equals,
  IsNull,
  Function,
} = q;

export const ALL_CLASSROOMS = gql`
  query ($size: Int, $cursor: String) {
    allClassrooms(_size: $size, _cursor: $cursor) {
      data {
        _id
        name
      }
      before
      after
    }
  }
`;

export const allClassrooms = async (client) => {
  try {
    let response = await client.query(
      Map(
        Paginate(Match(Index('allClassrooms')), { size: 999 }),
        Lambda('classroomRef', {
          _id: Select(['id'],Var('classroomRef')),
          name: Select(['data', 'name'], Get(Var('classroomRef'))),
        })
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const CREATE_CLASSROOM = gql`
  mutation ($name: String!) {
    createClassroom(data: { name: $name }) {
      _id
      name
    }
  }
`;

export const createClassroom = async (client, {name}) => {
  try {
    let response = await client.query(
      Create(Collection('Classroom'), {
        data: {
          name: name,
        },
      })
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const get_all_attendance_tickets = async (client) => {
  try {
    let r = await client.query(
      Map(
        Paginate(
          Range(
            Match(Index('attendance_tickets_by_date_range')),
            [TimeSubtract(Now(), 2, 'hours')],
            [TimeAdd(Now(), 24, 'hours')]
          ),
          { size: 9999 }
        ),

        Lambda('ticket', {
          ticket_id: Select(['ref', 'id'], Get(Select([1], Var('ticket')))),
          course: Select(
            ['data'],
            Get(Select(['data', 'course'], Get(Select([1], Var('ticket')))))
          ),
          classroom: Select(
            ['data'],
            Get(
              Select(
                ['data', 'classroom'],
                Get(
                  Select(
                    ['data', 'term'],
                    Get(
                      Select(
                        ['data', 'course'],
                        Get(Select([1], Var('ticket')))
                      )
                    )
                  )
                )
              )
            )
          ),
          teacher: Select(
            ['data'],
            Get(
              Select(
                ['data', 'teacher'],
                Get(Select(['data', 'course'], Get(Select([1], Var('ticket')))))
              )
            )
          ),
          student: If(
            Equals(
              Select(['data', 'student'], Get(Select([1], Var('ticket')))),
              'None'
            ),
            { name: 'Released Ticket' },
            Select(
              ['data'],
              Get(Select(['data', 'student'], Get(Select([1], Var('ticket')))))
            )
          ),
          date: Select(['data', 'date'], Get(Select([1], Var('ticket')))),
          attended: Select(
            ['data', 'attended'],
            Get(Select([1], Var('ticket')))
          ),
          catchup: Select(['data', 'catchup'], Get(Select([1], Var('ticket')))),
        })
      )
    );
    return r;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const get_class_attendance_tickets = async (client, class_id) => {
  try {
    let r = await client.query(
      Map(
        Paginate(
          Match(
            Index('attendance_tickets_by_class'),
            Ref(Collection('Class'), class_id)
          )
        ),
        Lambda('ticket', {
          ticket_id: Select(['ref', 'id'], Get(Var('ticket'))),
          course: Select(
            ['data'],
            Get(Select(['data', 'course'], Get(Var('ticket'))))
          ),
          student: If(
            Equals(Select(['data', 'student'], Get(Var('ticket'))), 'None'),
            { name: 'Released Ticket' },
            Select(
              ['data'],
              Get(Select(['data', 'student'], Get(Var('ticket'))))
            )
          ),
          date: Select(['data', 'date'], Get(Var('ticket'))),
          attended: Select(['data', 'attended'], Get(Var('ticket'))),
          catchup: Select(['data', 'catchup'], Get(Var('ticket'))),
        })
      )
    );
    return r;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const attempt_class = async (client, ticketId) => {
  try {
    let r = await client.query(Call(q.Function('attempt_class'), ticketId));
    return r;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const help_drop_in = async (client, ticketId, studentID) => {
  try {
    let r = await client.query(
      Let(
        {
          ticket: Get(Ref(Collection('AttendanceTicket'), ticketId)),
          student: Get(Ref(Collection('Student'), studentID)),
          classs: Select(['data', 'session'], Var('ticket')),
        },
        Do(
          If(IsNull(Var('student')), Abort('student not exist'), 'go'),
          Call(Function('add_log'), [
            Select(['collection', 'id'], CurrentIdentity()),
            Select(['id'], CurrentIdentity()),
            'Class',
            Select(['id'], Var('classs')),
            `helped ${studentID} drop to`,
          ]),
          Update(Ref(Collection('AttendanceTicket'), ticketId), {
            data: { student: Ref(Collection('Student'), studentID) },
          })
        )
      )
    );
    return r;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const cancel_attempt_class = async (client, ticketId) => {
  try {
    let r = await client.query(
      Let(
        {
          ticket: Get(Ref(Collection('AttendanceTicket'), ticketId)),
          course: Select(['data', 'course'], Var('ticket')),
          classs: Select(['data', 'session'], Var('ticket')),
          student: Select(['data', 'student'], Var('ticket')),
        },
        Do(
          If(
            IsNull(Var('student')),
            Abort('can not make attempt'),
            'Can go create attempt'
          ),
          Call(Function('add_log'), [
            'Student',
            Select(['id'], Var('student')),
            'Class',
            Select(['id'], Var('classs')),
            'Cancel attempt Class',
          ]),
          Update(Ref(Collection('AttendanceTicket'), ticketId), {
            data: { attended: false },
          })
        )
      )
    );
    return r;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const exportItems = {
  ALL_CLASSROOMS,
  get_class_attendance_tickets,
  get_all_attendance_tickets,
  attempt_class,
  cancel_attempt_class,
  help_drop_in,
};

export default exportItems;
