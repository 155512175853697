import React, { useState } from 'react';
import {
  LoginFormContainer,
  LoginInputField,
  LoginCardButton,
} from '../styles/LoginStyle';

import { useFaunaContext } from '../../../providers/FaunaProvider';

import { useHistory } from 'react-router-dom';

import { parseErrorMessageToJSON } from '../../../faunadb/helpers/utils';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { queries, client, setClient } = useFaunaContext();
  const history = useHistory();

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    queries.auth
      .login(client, username, password, setClient)
      .then((value) => {
        setLoading(false);
        if (value?.data?.teacher?.super_admin === true) {
          history.replace('/dashboard');
        } else {
          history.replace('/attend');
        }
      })
      .catch(({ message }) => {
        console.log(parseErrorMessageToJSON(message));
        setLoading(false);
      });
  };

  return (
    <>
      <LoginFormContainer>
        <LoginInputField
          value={username}
          placeholder="username"
          type="text"
          onChange={({ target }) => {
            setUsername(target.value);
          }}
        ></LoginInputField>
        <LoginInputField
          value={password}
          placeholder="password"
          type="password"
          onChange={({ target }) => {
            setPassword(target.value);
          }}
        ></LoginInputField>

        <LoginCardButton onClick={login} disabled={loading}>
          Login
        </LoginCardButton>
      </LoginFormContainer>
    </>
  );
};

export default LoginForm;
