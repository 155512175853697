import gql from "graphql-tag";
import faunadb, { Collection, Update } from "faunadb";
const q = faunadb.query;
const { Call,Paginate,Match,Index,Lambda,Get,Var,Map,Ref } = q;

const ADD_TEACHER = async (client, name, email, password, icon, setClient) => {
  try {
    let response = await client.query(
      Call(q.Function("add_teacher"), {
        name: name,
        email: email,
        password: password,
        deleted: false,
        icon: icon,
      })
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

export const getAllTeachers = async (client) => {
  try {
    let response = await client.query(
      Map(
        Paginate(Match(Index("allTeachers")), { size: 999 }),
        Lambda('teacherRef', Get(Var('teacherRef')))
      )
    );
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
};

export const DELETE_TEACHER = gql`
  mutation($id: ID!) {
    deleteTeacher(id: $id) {
      _id
    }
  }
`;

export const deleteTeacher = async(client,id) =>{
  try{
    let response = await client.query(
      Update(
        Ref(Collection("Teacher"),id),{
          data:{
            deleted:true
          }
        }
      )
    );
    return response;
  }
  catch(error){
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
}

export const UPDATE_TEACHER = gql`
  mutation(
    $id: ID!
    $name: String
    $email: String
    $icon: String
    $deleted: Boolean
  ) {
    updateTeacher(
      id: $id
      data: { name: $name, email: $email, icon: $icon, deleted: $deleted }
    ) {
      _id
    }
  }
`;

export const GET_TEACHER = gql`
  query($id: ID!) {
    findTeacherByID(id: $id) {
      _id
      name
      email
      icon
      deleted
      courses {
        data {
          _id
          name
          quota
          price
          term {
            _id
            name
            start_date
            end_date
            deleted
          }
          teacher {
            _id
            name
            email
            icon
            deleted
          }
        }
      }
    }
  }
`;

const exportItems = { ADD_TEACHER };

export default exportItems;
