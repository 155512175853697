import React from 'react';
import {useQuery as useReactQuery, useMutation as useReactMutation, useQueryClient} from 'react-query';

import PopupContainer from '../../../styles/common/PopupContainer';
import Popup from 'reactjs-popup';
import TermForm from './TermForm';
import { allTerms, updateTerm } from '../../../faunadb/queries/term';
import { CardButton } from '../../../styles/common/Card';
import { Wrapper } from '../../../styles/common/Wrapper';
import { Badge, Button, Table } from 'rsuite';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useFaunaContext } from '../../../providers/FaunaProvider';

const TermList = () => {
  const {client} = useFaunaContext();
  const {data, loading} = useReactQuery('allTerms', async()=>await allTerms(client));

  const history = useHistory();
  const queryClient = useQueryClient();
  
  const updateTermMutation = useReactMutation(term=>updateTerm(client,term),{
    onSuccess: () => {
      queryClient.invalidateQueries('allTerms');}
  });

  if (loading) {
    return <></>;
  }
  return (
    <>
      <Wrapper>
        <Popup
          trigger={
            <CardButton style={{ maxWidth: '150px' }}>Create Term</CardButton>
          }
          modal
        >
          {(close) => (
            <PopupContainer>
              <TermForm close={close}></TermForm>
            </PopupContainer>
          )}
        </Popup>
        <Table
          virtualized
          style={{ width: '100%' }}
          autoHeight
          data={data?.data
            .slice()
            .filter(function (c) {
              return !c.deleted;
            })
            .sort(function (a, b) {
              return b._ts - a._ts;
            })}
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <Badge color={rowData.status === 1 ? 'green' : 'red'}>
                  {rowData.name}
                </Badge>
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Classroom</Table.HeaderCell>
            <Table.Cell dataKey="classroom.name" />
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Period</Table.HeaderCell>
            <Table.Cell>
              {(term) => `${term.start_date.value} - ${term.end_date.value}`}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Term Break Period</Table.HeaderCell>
            <Table.Cell>
              {(term) => {
                if (!term?.break_start_date) return '';
                return `${term.break_start_date.value} - ${term.break_end_date.value}`;
              }}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1} fixed="right">
            <Table.HeaderCell>Action</Table.HeaderCell>

            <Table.Cell>
              {(term) => {
                function handleAction() {
                  Swal.fire({
                    title: 'Do you want to delete this term?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    denyButtonText: `Don't save`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      updateTermMutation.mutate({
                        ...term,
                        deleted: true,
                        status: 0,
                      });
                    }
                  });
                }
                return (
                  <span>
                    <Button
                      appearance="link"
                      onClick={() => history.push(`/term/${term._id}`)}
                    >
                      {' '}
                      View{' '}
                    </Button>{' '}
                    |
                    <Button appearance="link" onClick={handleAction}>
                      {' '}
                      Remove{' '}
                    </Button>
                  </span>
                );
              }}
            </Table.Cell>
          </Table.Column>
        </Table>
      </Wrapper>
    </>
  );
};

export default TermList;
