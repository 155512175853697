import React from 'react';
import { toast } from 'react-toastify';
import DashboardCard from '../../components/card/DashboardCard';
import { useFaunaContext } from '../../providers/FaunaProvider';
import Logging from '../logging/Loggings';
import Settings from './components/Settings';
import ResetPromoteVIP from './components/ResetPromoteVIP';
import { Button } from 'rsuite';
import { Label } from '../../styles/common/Form';
import ReleaseAllQuota from './components/ReleaseAllQuota';

const SettingsPage = () => {
  const { queries, client } = useFaunaContext();
  return (
    <>
      <DashboardCard title="General Settings">
        <Settings />
      </DashboardCard>
      <DashboardCard title="VIP Controller">
        <ResetPromoteVIP />
      </DashboardCard>
      <DashboardCard title="Catch up credit Controller">
        <Label>將所有catchup credit 重設，每個sem 開始前建議先重設</Label>
        <Button
          onClick={() => {
            queries.student.reset_all_catch_up_credit(client).then((value) => {
              if (value) {
                toast.success('Catch up credit reset!');
              }
            });
          }}
          style={{ marginLeft: '5px', marginRight: '5px' }}
        >
          Reset all catch up credit
        </Button>
      </DashboardCard>
      <DashboardCard title="Release all quota">
        <ReleaseAllQuota />
      </DashboardCard>
      <DashboardCard title="Logging">
        <Logging />
      </DashboardCard>
    </>
  );
};

export default SettingsPage;
