import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  GET_GENERAL_SETTING,
  UPDATE_GENERAL_SETTING,
  getGeneralSetting,
  updateGeneralSetting,
} from '../../../faunadb/queries/setting';
import { useForm, Controller } from 'react-hook-form';
import { Form, Label } from '../../../styles/common/Form';
import { toast } from 'react-toastify';
import { Button, DateRangePicker } from 'rsuite';
import {useFaunaContext} from '../../../providers/FaunaProvider';
import {useQuery as useReactQuery, useMutation as useReactMutation , useQueryClient} from 'react-query';

const Settings = () => {
  const {client} = useFaunaContext();
  const queryClient = useQueryClient();

  const {data, loading} = useReactQuery(['generalSetting', process.env.REACT_APP_PERIOD_SETTING], async () => await getGeneralSetting(client, process.env.REACT_APP_PERIOD_SETTING));

  const updateSettingMutation = useReactMutation((payload) => updateGeneralSetting(client, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('generalSetting');
      toast.success('Updated!');
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const { handleSubmit, watch, control } = useForm();
  const vipStartDate = watch('vipStartDate');
  const vipEndDate = watch('vipEndDate');
  const normalStartDate = watch('normalStartDate');
  const normalEndDate = watch('normalEndDate');
  const onSubmit = (payload) => {
    const p = {
      vipStartDate:
        payload.vipStartDate === undefined
          ? new Date(data.data.vip_apply_start_date.value)
          : payload.vipStartDate[0],
      vipEndDate:
        payload.vipStartDate === undefined
          ? new Date(data.data.vip_apply_end_date.value)
          : payload.vipStartDate[1],
      normalStartDate:
        payload.normalStartDate === undefined
          ? new Date(data.data.normal_apply_start_date.value)
          : payload.normalStartDate[0],
      normalEndDate:
        payload.normalStartDate === undefined
          ? new Date(data.data.normal_apply_end_date.value)
          : payload.vipStartDate[1],
    };
    if (
      p.vipStartDate &&
      p.vipEndDate &&
      p.normalStartDate &&
      p.normalEndDate
    ) {
      updateSettingMutation.mutate(p);
    } else {
      toast.error('One of the date has no chagned');
    }
  };
  return (
    <>
      {!loading && data && data.data && (
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Label>Vip Application Start Date:</Label>
            <Controller
              as={
                <DateRangePicker
                  format="yyyy-MM-dd HH:mm"
                  defaultValue={[
                    vipStartDate ||
                      new Date(
                        data.data.vip_apply_start_date.value
                      ),
                    vipEndDate ||
                      new Date(data.data.vip_apply_end_date.value),
                  ]}
                />
              }
              control={control}
              name="vipStartDate"
              default={[
                vipStartDate ||
                  new Date(data.data.vip_apply_start_date.value),
                vipEndDate ||
                  new Date(data.data.vip_apply_end_date.value),
              ]}
            />
            <Label>General Application Start Date:</Label>
            <Controller
              as={
                <DateRangePicker
                  format="yyyy-MM-dd HH:mm"
                  defaultValue={[
                    normalStartDate ||
                      new Date(
                        data.data.normal_apply_start_date.value
                      ),
                    normalEndDate ||
                      new Date(
                        data.data.normal_apply_end_date.value
                      ),
                  ]}
                />
              }
              control={control}
              name="normalStartDate"
              default={[
                normalStartDate ||
                  new Date(data.data.normal_apply_start_date.value),
                normalEndDate ||
                  new Date(data.data.normal_apply_end_date.value),
              ]}
            />
            <Label></Label>

            <Button type="submit">Update</Button>
          </Form>
        </>
      )}
    </>
  );
};

export default Settings;
