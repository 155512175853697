import DashboardCard from "../../components/card/DashboardCard";
import PerformanceTermTable from "./components/performance-term-table";
import PerformanceTermForm from "./create-term";

export default function PerformancePage() {
    return <>
        <DashboardCard title={"Performance Term"}>
            <PerformanceTermForm></PerformanceTermForm>
        </DashboardCard>
        <DashboardCard title={"Performance Term List"}>
            <PerformanceTermTable />
        </DashboardCard>
    </>
}