import { css } from 'styled-components';
import moment from 'moment';
import { toast } from 'react-toastify';
import faunadb, { GT } from 'faunadb';
const q = faunadb.query;
const {
  Call,
  Update,
  Ref,
  Collection,
  Map,
  Paginate,
  Range,
  Match,
  Index,
  Get,
  Lambda,
  Var,
  CurrentIdentity,
  If,
  ContainsPath,
  Do,
  Let
} = q;

function flattenDataKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map((e) => flattenDataKeys(e));
  } else if (typeof obj === 'object') {
    // the case where we have just data pointing to an array.
    if (Object.keys(obj).length === 1 && obj.data && Array.isArray(obj.data)) {
      return flattenDataKeys(obj.data);
    } else {
      Object.keys(obj).forEach((k) => {
        if (k === 'data') {
          const d = obj[k];
          delete obj.data;

          Object.keys(d).forEach((dataKey) => {
            obj[dataKey] = flattenDataKeys(d[dataKey]);
          });
        } else {
          obj[k] = flattenDataKeys(obj[k]);
        }
      });
    }
    return obj;
  } else {
    return obj;
  }
}

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
}

function parseErrorMessageToJSON(message) {
  return JSON.parse(message);
}

function breakpoints(
  cssProp = 'padding', // the CSS property to apply to the breakpoints
  cssPropUnits = 'px', // the units of the CSS property (can set equal to "" and apply units to values directly)
  values = [], // array of objects, e.g. [{ 800: 60 }, ...] <-- 800 (key) = screen breakpoint, 60 (value) = CSS prop breakpoint
  mediaQueryType = 'max-width' // media query breakpoint type, i.e.: max-width, min-width, max-height, min-height
) {
  const breakpointProps = values.reduce((mediaQueries, value) => {
    const [screenBreakpoint, cssPropBreakpoint] = [
      Object.keys(value)[0],
      Object.values(value)[0],
    ];
    return (mediaQueries += `
    @media screen and (${mediaQueryType}: ${screenBreakpoint}px) {
      ${cssProp}: ${cssPropBreakpoint}${cssPropUnits};
    }
    `);
  }, '');
  return css([breakpointProps]);
}

function getDateInBetweenWithWeekDay(start, end, time, weekday) {
  let dateMatch = [];
  try {
    let startDate = moment(start)
      .hour(time.split(':')[0])
      .minutes(time.split(':')[1]);
    let endDate = moment(end)
      .hour(time.split(':')[0])
      .minutes(time.split(':')[1]);
    while (!startDate.isAfter(endDate)) {
      if (startDate.format('dddd') === weekday) {
        let temp = startDate.toISOString();
        dateMatch.push(temp);
      }
      startDate = startDate.add('1', 'day');
    }
  } catch {
    return [];
  }
  return dateMatch;
}

function getWeekday(weekday) {
  if (weekday === 1) return 'Monday';
  if (weekday === 2) return 'Tuesday';
  if (weekday === 3) return 'Wednesday';
  if (weekday === 4) return 'Thursday';
  if (weekday === 5) return 'Friday';
  if (weekday === 6) return 'Saturday';
  if (weekday === 7) return 'Sunday';
  return weekday;
}

/**
 * Simple safari detection based on user agent test
 */
export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isJsons = (array) =>
  Array.isArray(array) &&
  array.every((row) => typeof row === 'object' && !(row instanceof Array));

export const isArrays = (array) =>
  Array.isArray(array) && array.every((row) => Array.isArray(row));

export const jsonsHeaders = (array) =>
  Array.from(
    array
      .map((json) => Object.keys(json))
      .reduce((a, b) => new Set([...a, ...b]), [])
  );

export const jsons2arrays = (jsons, headers) => {
  headers = headers || jsonsHeaders(jsons);

  // allow headers to have custom labels, defaulting to having the header data key be the label
  let headerLabels = headers;
  let headerKeys = headers;
  if (isJsons(headers)) {
    headerLabels = headers.map((header) => header.label);
    headerKeys = headers.map((header) => header.key);
  }

  const data = jsons.map((object) =>
    headerKeys.map((header) => getHeaderValue(header, object))
  );
  return [headerLabels, ...data];
};

export const getHeaderValue = (property, obj) => {
  const foundValue = property
    .replace(/\[([^\]]+)]/g, '.$1')
    .split('.')
    // eslint-disable-next-line array-callback-return
    .reduce(function (o, p, i, arr) {
      // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
      if (o[p] === undefined) {
        arr.splice(1);
      } else {
        return o[p];
      }
    }, obj);
  // if at any point the nested keys passed do not exist then looks for key `property` in object obj
  return foundValue === undefined
    ? property in obj
      ? obj[property]
      : ''
    : foundValue;
};

export const elementOrEmpty = (element) =>
  typeof element === 'undefined' || element === null ? '' : element;
export const joiner = (data, separator = ',', enclosingCharacter = '"') => {
  return data
    .filter((e) => e)
    .map((row) =>
      row
        .map((element) => elementOrEmpty(element))
        .map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`)
        .join(separator)
    )
    .join(`\n`);
};

export const arrays2csv = (data, headers, separator, enclosingCharacter) =>
  joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter);

export const jsons2csv = (data, headers, separator, enclosingCharacter) =>
  joiner(jsons2arrays(data, headers), separator, enclosingCharacter);

export const string2csv = (data, headers, separator, enclosingCharacter) =>
  headers ? `${headers.join(separator)}\n${data}` : data;

export const toCSV = (data, headers, separator, enclosingCharacter) => {
  if (isJsons(data))
    return jsons2csv(data, headers, separator, enclosingCharacter);
  if (isArrays(data))
    return arrays2csv(data, headers, separator, enclosingCharacter);
  if (typeof data === 'string') return string2csv(data, headers, separator);
  throw new TypeError(
    `Data should be a "String", "Array of arrays" OR "Array of objects" `
  );
};

export const buildURI = (
  data,
  uFEFF,
  headers,
  separator,
  enclosingCharacter
) => {
  const csv = toCSV(data, headers, separator, enclosingCharacter);
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type });
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === 'undefined'
    ? dataURI
    : URL.createObjectURL(blob);
};

export const printErrorMessageFromFQL = (errors) => {
  errors.map((error) =>
    // eslint-disable-next-line array-callback-return
    error.cause.map((c) => {
      toast.error(
        String(c.description)
          .toUpperCase()
          .replaceAll(' ', '_')
          .replaceAll('.', '')
      );
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text:
      //     c.description === "document is not unique."
      //       ? "You are already in this course!"
      //       : c.description,
      // });
    })
  );
};

function buildKey({ classroom, course, date, teacher }) {
  const key = JSON.stringify({
    classroom: classroom?.name,
    course: course?.name,
    date: date?.value,
    teacher: teacher?.name,
  });
  return key;
}

function Select(path, v){
  return If(ContainsPath(path,v),q.Select(path, v),null)
}

export {
  flattenDataKeys,
  isFunction,
  parseErrorMessageToJSON,
  breakpoints,
  getDateInBetweenWithWeekDay,
  getWeekday,
  buildKey,
  Select
};
