import moment from 'moment';
import React, { useRef } from 'react';
import { Button, Input } from 'rsuite';
import {
  CardBody,
  CardButton,
  CardFieldset,
  CardHeader,
  CardHeading,
  CardOptions,
  CardWrapper,
} from '../../../styles/common/Card';

const TicketCard = ({ ticket, takeAttendance, helpDropIn }) => {
  const studentIDRef = useRef();
  const submitDropIn = () => {
    helpDropIn(ticket.ticket_id, studentIDRef.current.value);
  };
  return (
    <CardWrapper>
      <CardFieldset style={{ textAlign: 'end', fontSize: 8 }}>
        {ticket?.ticket_id}
      </CardFieldset>
      <CardHeader>
        <CardHeading>
          {ticket.student?.name || ticket.student.email}
        </CardHeading>
        {ticket.catchup && <CardFieldset>(Catch up student)</CardFieldset>}

        <CardFieldset>Phone:{ticket.student?.phone_number}</CardFieldset>
        <CardFieldset>
          Date:{moment(ticket.date.value).format('YYYY-MM-DD HH:mm')}
        </CardFieldset>
        {!ticket.attended && !ticket.student?.email && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Input
                ref={studentIDRef}
                placeholder="Enter Student ID to drop in"
              />
              <Button style={{ margin: '1rem' }} onClick={submitDropIn}>
                Help Drop In
              </Button>
            </div>
          </>
        )}
      </CardHeader>
      <CardBody>
        <CardFieldset>
          {ticket.attended && (
            <CardOptions style={{ color: 'green' }}>Attended</CardOptions>
          )}
          {!ticket.attended && (
            <CardOptions style={{ color: 'red' }}>Not attended</CardOptions>
          )}
        </CardFieldset>
        {!ticket.attended && ticket.student?.email && (
          <CardFieldset
            onClick={() => {
              takeAttendance(ticket.ticket_id);
            }}
          >
            <CardButton>Take Attendance</CardButton>
          </CardFieldset>
        )}
      </CardBody>
    </CardWrapper>
  );
};

export default TicketCard;
