import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../../styles/common/Wrapper';
import styled from 'styled-components';
import { Button, Table } from 'rsuite';
import {
  getWeekday,
  printErrorMessageFromFQL,
} from '../../../faunadb/helpers/utils';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';
import { GET_TERM } from '../../../faunadb/queries/term';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { useQueryClient } from 'react-query';

const SearchBox = styled.input`
  margin: 15px auto;
  height: 3em;
  border-radius: 3em;
  border: none;
  padding: 0px 2em;
  min-width: 400px;
  &:focus {
    outline: none;
  }
`;
const CourseList = ({ term }) => {
  const history = useHistory();
  const [filterValue, setFilterValue] = useState('');
  const [courses, setCourses] = useState([]);
  const queryClient = useQueryClient();
  // const [updateGetTermCache] = useLazyQuery(GET_TERM, {
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     id: term._id,
  //   },
  // });
  const { queries, client } = useFaunaContext();
  useEffect(() => {
    let tempCourses = term.courses.data
      .slice()
      .sort((a, b) => a.weekday - b.weekday);
    tempCourses = tempCourses.filter(
      (course) =>
        JSON.stringify(course)
          .toLowerCase()
          .includes(filterValue.toLowerCase()) && !course?.deleted
    );
    setCourses(tempCourses);
  }, [filterValue, term]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SearchBox
          placeholder="search by email / name / vip / phone number"
          value={filterValue}
          onChange={({ target }) => {
            setFilterValue(target.value);
          }}
        ></SearchBox>
      </div>
      {!term.deleted && (
        <Wrapper>
          <Button
            onClick={() => history.push(`/term-create-course/${term._id}`)}
            style={{ maxWidth: '150px' }}
          >
            Create Course
          </Button>
          {/* <Popup
            trigger={
            }
            modal
          >
            {(close) => (
              <PopupContainer>
                <CourseForm term={term} close={close}></CourseForm>
              </PopupContainer>
            )}
          </Popup> */}
          <Table
            data={courses}
            autoHeight
            virtualized
            style={{ width: '100%' }}
          >
            <Table.Column width={200}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column>
              <Table.HeaderCell>Teacher</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.teacher.name}</Table.Cell>
            </Table.Column>
            <Table.Column>
              <Table.HeaderCell>Weekday</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => getWeekday(rowData.weekday)}
              </Table.Cell>
            </Table.Column>
            <Table.Column>
              <Table.HeaderCell>Start at</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => getWeekday(rowData.start_at)}
              </Table.Cell>
            </Table.Column>
            <Table.Column>
              <Table.HeaderCell>Quota</Table.HeaderCell>
              <Table.Cell dataKey="quota" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  const discounts = rowData?.discount_group?.discount_series
                    ? rowData.discount_group.discount_series
                    : [0];
                  return discounts.map(
                    (discount) => `$${rowData.price - discount},`
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Discount Group</Table.HeaderCell>
              <Table.Cell dataKey="discount_group.name" />
            </Table.Column>

            <Table.Column width={60} fixed="right">
              <Table.HeaderCell></Table.HeaderCell>

              <Table.Cell>
                {(rowData) => {
                  return (
                    <Button
                      appearance="link"
                      onClick={() => history.push(`/course/${rowData._id}`)}
                    >
                      View
                    </Button>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={60} fixed="right">
              <Table.HeaderCell></Table.HeaderCell>

              <Table.Cell>
                {(rowData) => {
                  function handleAction() {
                    Swal.fire({
                      title: 'Do you want to delete this course?',
                      showCancelButton: true,
                      confirmButtonText: 'Delete',
                      denyButtonText: `Don't save`,
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        queries.course
                          .DELETE_COURSE(client, rowData._id)
                          .then((response) => {
                            if (response.errors) {
                              printErrorMessageFromFQL(response.errors);
                            } else {
                              toast.success('Course deleted');
                              Swal.fire('Course deleted!', '', 'success');
                              queryClient.invalidateQueries(['term', term._id]);
                            }
                          })
                          .finally(() => { });
                      }
                    });
                  }
                  return (
                    <span>
                      <Button appearance="link" onClick={handleAction}>
                        Remove
                      </Button>
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </Wrapper>
      )}
    </>
  );
};

export default CourseList;
