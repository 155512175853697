import styled from "styled-components";

export const LoginBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: rgb(2, 0, 36); */
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const LoginCard = styled.div`
  background: rgba(255, 255, 255, 0.2);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoginCardBody = styled.div`
  padding: 45px 45px 30px 45px;
`;

export const LoginCardLogo = styled.img``;
export const LoginCardTitle = styled.h2`
  color: #b5b5b5;
`;

export const LoginCardButton = styled.button`
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.15);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); */
  border-radius: 10px;
  border: none;
  padding: 15px 32px;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
  }
`;

export const LineBreak = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const LoginFormContainer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-direction: column;
`;
export const LoginInputField = styled.input`
  margin: 15px 0px 15px 0px;
  padding: 15px;
  width: 100%;
  color: #000;
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none;
  ::placeholder {
    font-weight: strong;
    color: #000;
  }
`;
