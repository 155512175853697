import gql from 'graphql-tag';
import faunadb from 'faunadb';
import { Select } from '../helpers/utils';

const q = faunadb.query;
const {
  Do,
  Let,
  Ref,
  Collection,
  Var,
  Time,
  Map,
  Index,
  Lambda,
  Match,
  Paginate,
  Update,
  // Select,
  Get
} = q;

export const GET_CLASS = gql`
  query ($id: ID!) {
    findClassByID(id: $id) {
      _id
      date
      end_date
      course {
        name
        quota
        price
        weekday
        teacher {
          _id
          name
          email
          icon
        }
      }
      attendees {
        data {
          name
          email
        }
      }
    }
  }
`;

export const getClass = async(client, id) => {
  try{
    let response = await client.query(
      Let(
        {
          id: id,
          class: Get(Ref(Collection('Class'), Var('id'))),
          course: Get(Select(['data', 'course'], Var('class'))),
          teacher: Get(Select(['data', 'teacher'], Var('course'))),
          attendees: Map(
            Paginate(Match(Index('class_attendees_by_class'), Var('id'))),
            Lambda('attendeeRef', Get(Var('attendeeRef')))
          )
        },
        {
          _id: Select(['ref', 'id'], Var('class')),
          date: Select(['data', 'date'], Var('class')),
          end_date: Select(['data', 'end_date'], Var('class')),
          course: {
            name: Select(['data', 'name'], Var('course')),
            quota: Select(['data', 'quota'], Var('course')),
            price: Select(['data', 'price'], Var('course')),
            weekday: Select(['data', 'weekday'], Var('course')),
            teacher: {
              _id: Select(['ref', 'id'], Var('teacher')),
              name: Select(['data', 'name'], Var('teacher')),
              email: Select(['data', 'email'], Var('teacher')),
            }
          },
          attendees: Var('attendees')
        }
      ))
      return response;
    } catch (error) {
      return JSON.parse(error.requestResult.responseRaw);
    }
  }

export const editClassDate = async (client, classId, startDate, endDate) => {
  try {
    let response = await client.query(
      Let(
        {
          c: Ref(Collection("Class"), classId),

          target_date: Time(startDate),
          target_end_date: Time(endDate)
        },
        Do(
          Update(Var("c"), {
            data: {
              date: Var("target_date"),
              end_date: Var("target_end_date")
            }
          }),
          Map(
            Paginate(Match(Index("attendance_tickets_by_class"), Var("c"))),
            Lambda(
              "a",
              Update(Var("a"), {
                data: {
                  date: Var("target_date"),
                  end_date: Var("target_end_date")
                }
              })
            )
          )
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
}

const exportItems = {
  GET_CLASS,
};

export default exportItems;
