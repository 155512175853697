import React, { useEffect, useState } from "react";
import { useFaunaContext } from "../../providers/FaunaProvider";
import DashboardCard from "../../components/card/DashboardCard";
import StudentList from "./components/StudentList";
import ExportVIPStudentListButton from "./components/ExportVIPStudentListButton";
import { useQuery } from "react-query";
import { getAllStudents } from "../../faunadb/queries/student";

const Student = () => {
  const {client } = useFaunaContext();
  const {data} = useQuery('allStudents', async()=> await getAllStudents(client));
  console.log(data);
  return (
    <>
      <DashboardCard title="Student List">
        <ExportVIPStudentListButton students={data?.data} />
        {data?.data && data?.data.length > 0 && <StudentList students={data?.data} />}
      </DashboardCard>
    </>
  );
};

export default Student;
