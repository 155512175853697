import auth from "./auth";
import student from "./student";
import term from "./term";
import classroom from "./classroom";
import classquery from "./classquery";
import teacher from "./teacher";
import course from "./course";
import log from "./log";
import order from "./order";

const exportItem = {
  auth,
  student,
  term,
  classroom,
  classquery,
  teacher,
  log,
  course,
  order,
};

export default exportItem;
