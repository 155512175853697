import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useFaunaContext } from "../../../providers/FaunaProvider";
import { Form, InputField, Label } from "../../../styles/common/Form";
import { CardButton } from "../../../styles/common/Card";
import { toast } from "react-toastify";

const TeacherForm = ({ close }) => {
  const { register, handleSubmit } = useForm();
  const { queries, client } = useFaunaContext();
  const [loading, setLoading] = useState(false);
  const [iconUrl, setIconUrl] = useState("");

  const onSubmit = (data) => {
    setLoading(true);
    queries.teacher
      .ADD_TEACHER(client, data.name, data.email, data.password, iconUrl)
      .then((response) => {
        setLoading(false);
        // history.go(0);
        toast.success("Teacher created");
        // close();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>Name</Label>
        <InputField name="name" ref={register({ required: true })} />
        <Label>Email</Label>
        <InputField
          name="email"
          ref={register({ required: true })}
          type="email"
          autoComplete="off"
        />
        <Label>Password</Label>
        <InputField
          name="password"
          ref={register({ required: true })}
          type="password"
          autoComplete="off"
        />
        <Label>Icon Url</Label>
        <InputField
          name="icon"
          type="text"
          autoComplete="off"
          value={iconUrl}
          defaultValue={iconUrl}
          onChange={({ target }) => setIconUrl(target.value)}
        />
        <CardButton
          type="button"
          onClick={() => {
            const input = document.createElement("input");

            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();

            input.onchange = async () => {
              const file = input.files[0];
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = async () => {
                const fileData = {
                  base64: reader.result,
                  size: file.size,
                  path: file.path,
                  type: file.type,
                };
                setIconUrl("Uploading...");
                const rawResponse = await fetch(process.env.REACT_APP_S3_URL, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ base64: fileData.base64 }),
                });
                const content = await rawResponse.json();
                setIconUrl(content.url);
              };
            };
          }}
        >
          Upload Icon
        </CardButton>
        <Label>Submit</Label>

        {!loading && <InputField type="submit" />}
      </Form>
    </>
  );
};

export default TeacherForm;
