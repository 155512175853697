import moment from 'moment';
import { toast } from 'react-toastify';
import { Checkbox, Divider, Panel, Table } from 'rsuite';
import { useFaunaContext } from '../../../providers/FaunaProvider';

export default function AttendPanelByCourse({ header, tickets, refetch }) {
  const { queries, client } = useFaunaContext();
  const objectOfHeader = JSON.parse(header);
  const totalStudent = tickets.length;
  const totalAttended = tickets.reduce((count, item) => {
    if (item?.attended === true) return count + 1;
    else return count;
  }, 0);
  const takeAttendance = async (ticketId) => {
    const attendanceResponse = await queries.classroom.attempt_class(
      client,
      ticketId
    );
    if (attendanceResponse?.data) {
      refetch();
      toast.success('Student Attended!');
    }
  };

  const cancelAttend = async (ticketId) => {
    const r = await queries.classroom.cancel_attempt_class(client, ticketId);
    if (r?.data) {
      refetch();
      toast.success('Cancel Attended!');
    }
  };
  return (
    <>
      <div className="rs-panel-header" style={{ paddingBottom: 0 }}>
        {moment(objectOfHeader.date).local().format('LLLL')}
      </div>
      <Panel
        header={`[${objectOfHeader.classroom} - ${objectOfHeader.teacher}] ${objectOfHeader.course} (${totalAttended}/${totalStudent})`}
      >
        <Table
          style={{ width: '100%' }}
          data={tickets}
          autoHeight
          wordWrap={'break-word'}
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>No.</Table.HeaderCell>
            <Table.Cell>{(_, index) => index + 1}</Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell dataKey="student.name" />
          </Table.Column>
          <Table.Column width={300} flexGrow={1}>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.Cell dataKey="student.email" />
          </Table.Column>
          <Table.Column width={200} flexGrow={1}>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.Cell dataKey="student.phone_number" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>VIP</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return rowData?.student?.vip ? 'VIP' : 'General';
              }}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>VVIP</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return rowData?.student?.vvip ? 'VVIP' : '';
              }}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Is CatchUp?</Table.HeaderCell>
            <Table.Cell>
              {({ catchup }) => {
                if (catchup) return <Checkbox defaultChecked readOnly />;
                else return <Checkbox readOnly />;
              }}
            </Table.Cell>
          </Table.Column>
          <Table.Column fixed={'right'} flexGrow={1}>
            <Table.HeaderCell>Attended?</Table.HeaderCell>
            <Table.Cell>
              {({ attended, ticket_id }) => {
                return (
                  <Checkbox
                    checked={attended}
                    onChange={(v, checked) => {
                      if (!checked) {
                        cancelAttend(ticket_id);
                      }
                      if (checked) {
                        takeAttendance(ticket_id);
                      }
                    }}
                  />
                );
              }}
            </Table.Cell>
          </Table.Column>
        </Table>
      </Panel>
      <Divider />
    </>
  );
}
