import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, InputField, Label } from '../../styles/common/Form';
import { create_performance } from '../../faunadb/queries/performance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFaunaContext } from '../../providers/FaunaProvider';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import usePerformanceDayDetail from './hooks/usePerformanceDayDetail';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { ALL_CLASSROOMS, allClassrooms } from '../../faunadb/queries/classroom';
import ReactSelect from 'react-select';
import { SelectStyle } from '../../styles/common/Form';

function PerformanceForm() {
    const { handleSubmit, control, register } = useForm();
    const dayId = useParams().id;
    const { client } = useFaunaContext();
    const { isSuccess: isGetPDaySuccess, data: performanceDayData } = usePerformanceDayDetail(client, dayId);

    let classroomOptions = []
    // const { data: allClassrooms } = useGraphQLQuery(ALL_CLASSROOMS)

  const {data: allClassroomsData} = useQuery(['allClassrooms'], async()=>await allClassrooms(client));
    for (let i = 0; i < allClassroomsData?.data.length; i++) {
        classroomOptions.push({
            value: allClassroomsData?.data[i]._id,
            label: allClassroomsData?.data[i].name,
        });
    }

    const queryClient = useQueryClient();
    const mutation = useMutation(create_performance, {
        onSuccess: () => {
            queryClient.invalidateQueries(["performances", dayId]);
            toast.success('Performance Created');
        },
    });

    const onSubmit = (value) => {
        const termId = performanceDayData?.data.performance_term.value.id;
        if (!value?.classroom?.value) return;
        mutation.mutate({ client: client, termId: termId, dayId: dayId, classroomId: value.classroom.value, ...value });
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Label>Name</Label>
                <InputField name="name" ref={register({ required: true })} />
                <Label>Quota</Label>
                <InputField
                    name="quota"
                    type="number"
                    min="1"
                    max="100"
                    ref={register({ required: true })}
                />
                <Label>Classroom</Label>
                {classroomOptions.length > 0 && (<Controller
                    as={
                        <ReactSelect styles={SelectStyle} />
                    }
                    options={classroomOptions}
                    name="classroom"
                    isClearable
                    control={control}
                    defaultValue=""
                />)}
                {isGetPDaySuccess && <InputField type="submit" />}
            </Form>
        </>
    );
}

export default PerformanceForm;
