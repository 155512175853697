import { useParams } from 'react-router-dom';
import { Button, Table } from 'rsuite';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { useMutation, useQueryClient } from 'react-query';
import { delete_performance } from '../../../faunadb/queries/performance';
import usePerformancesByDay from '../hooks/usePerformancesByDay';

export default function PerformanceList() {
    const dayId = useParams().id;
    const { data, isLoading } = usePerformancesByDay(dayId);
    const history = useHistory();

    const { client } = useFaunaContext();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation(delete_performance, {
        onSuccess: () => {
            queryClient.invalidateQueries(['performances', dayId]);
        }
    })

    return (
        <>
            <Table loading={isLoading} data={data || []} height={500} virtualized style={{ marginTop: 72 }}>
                <Table.Column width={50}>
                    <Table.HeaderCell>No.</Table.HeaderCell>
                    <Table.Cell>{(_, index) => index + 1}</Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>P-Term.</Table.HeaderCell>
                    <Table.Cell dataKey={"performance_term.data.name"} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Classroom</Table.HeaderCell>
                    <Table.Cell dataKey={"classroom.data.name"} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>P-Name</Table.HeaderCell>
                    <Table.Cell dataKey={"performance_name"} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Price</Table.HeaderCell>
                    <Table.Cell dataKey={"price"} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Quota</Table.HeaderCell>
                    <Table.Cell dataKey={"quota"} />
                </Table.Column>

                <Table.Column flexGrow={1} fixed="right">
                    <Table.HeaderCell>Action</Table.HeaderCell>

                    <Table.Cell>
                        {(term) => {
                            function handleAction() {
                                Swal.fire({
                                    title: 'Do you want to delete this term?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Delete',
                                    denyButtonText: `Don't save`,
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        deleteMutation.mutate({
                                            client: client,
                                            id: term.ref.value.id
                                        });
                                    }
                                });
                            }
                            return (
                                <span>
                                    <Button
                                        appearance="link"
                                        onClick={() => { history.push(`/performance-detail/${term.ref.value.id}`) }}
                                    >
                                        {' '}
                                        View{' '}
                                    </Button>{' '}
                                    |
                                    <Button appearance="link" onClick={handleAction}>
                                        {' '}
                                        Remove{' '}
                                    </Button>
                                </span>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </>
    );
}
