import React, { useEffect, useState } from 'react';
import { useFaunaContext } from '../../providers/FaunaProvider';
import { PanelGroup } from 'rsuite';
import AttendPanelByCourse from './components/AttendPanelByCourse';
import { buildKey } from '../../faunadb/helpers/utils';

const AttendPage = () => {
  const { queries, client } = useFaunaContext();
  const [tickets, setTickets] = useState(new Map());

  const getAttendanceTickets = async () => {
    let temp = new Map();
    const response = await queries.classroom.get_all_attendance_tickets(client);
    if (response?.data) {
      response?.data
        .filter((r) => {
          return r?.student?.email;
        })
        .forEach((c) => {
          const mapKey = buildKey(c);
          let currentArray = temp.get(buildKey(c)) || [];
          currentArray.push(c);
          temp.set(mapKey, currentArray);
        });
    }
    setTickets(temp);
  };

  useEffect(() => {
    getAttendanceTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PanelGroup accordion bordered>
        {Array.from(tickets.keys()).map((key) => (
          <AttendPanelByCourse
            key={key}
            header={key}
            tickets={tickets.get(key)}
            refetch={getAttendanceTickets}
          />
        ))}
      </PanelGroup>
    </>
  );
};

export default AttendPage;
