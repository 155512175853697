import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  CardWrapper,
  CardBody,
  CardButton,
  CardHeader,
  CardFieldset,
  CardHeading,
  CardOptionsNote,
  CardDelete,
} from "../../../styles/common/Card";
import { useHistory } from "react-router-dom";
import {
  getWeekday,
  printErrorMessageFromFQL,
} from "../../../faunadb/helpers/utils";
import { GET_TERM } from "../../../faunadb/queries/term";
import { useFaunaContext } from "../../../providers/FaunaProvider";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {useQueryClient} from 'react-query'

const CourseCard = ({ course, termId }) => {
  // console.log(course);
  const history = useHistory();
  const { queries, client } = useFaunaContext();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const queryClient = useQueryClient();
  const discounts = course?.discount_group?.discount_series
    ? course.discount_group.discount_series
    : [0];
  return (
    <CardWrapper>
      <CardHeader>
        {course.term && <CardHeading>{course.term.name}</CardHeading>}
        {course.term && (
          <CardFieldset>
            {course.term.start_date} - {course.term.end_date}
          </CardFieldset>
        )}
        <CardHeading>{course.name}</CardHeading>
        {!deleteLoading && (
          <CardDelete
            onClick={() => {
              Swal.fire({
                title: "Do you want to delete this course?",
                showCancelButton: true,
                confirmButtonText: "Delete",
                denyButtonText: `Don't save`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  setDeleteLoading(true);
                  queries.course
                    .DELETE_COURSE(client, course._id)
                    .then((response) => {
                      if (response.errors) {
                        printErrorMessageFromFQL(response.errors);
                      } else {
                        toast.success("Course deleted");
                        Swal.fire("Course deleted!", "", "success");
                        // updateGetTermCache();
                        queryClient.invalidateQueries(["term", termId]);
                      }
                    })
                    .finally(() => setDeleteLoading(false));
                }
              });
            }}
          >
            <RiDeleteBin6Line />
          </CardDelete>
        )}
      </CardHeader>
      <CardBody>
        <CardFieldset>
          <CardOptionsNote>{course.teacher.name}</CardOptionsNote>
        </CardFieldset>
        <CardFieldset>
          <CardOptionsNote>
            {getWeekday(course.weekday)} {course.start_at}
          </CardOptionsNote>
        </CardFieldset>
        <CardFieldset>
          <CardOptionsNote>Quota ({course.quota})</CardOptionsNote>
        </CardFieldset>
        <CardFieldset>
          <CardOptionsNote>
            Price {discounts.map((discount) => `$${course.price - discount},`)}
          </CardOptionsNote>
        </CardFieldset>
        <CardFieldset>
          <CardOptionsNote>
            {" "}
            Discount:{course?.discount_group?.name}
          </CardOptionsNote>
        </CardFieldset>
        <CardFieldset>
          <CardButton
            type="button"
            onClick={() => history.push(`/course/${course._id}`)}
          >
            View course
          </CardButton>
        </CardFieldset>
      </CardBody>
    </CardWrapper>
  );
};

export default CourseCard;
