import React from "react";
import { getCourse } from "../../faunadb/queries/course";
import { getAllStudents } from "../../faunadb/queries/student";
import DashboardCard from "../../components/card/DashboardCard";
import ClassList from "./components/ClassList";
import StudentList from "../student/components/StudentList";
import InfoTable from "../../components/table/InfoTable";
import { useForm, Controller } from "react-hook-form";
import { Form, InputField, Label, SelectStyle } from "../../styles/common/Form";
import { useFaunaContext } from "../../providers/FaunaProvider";

import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { printErrorMessageFromFQL } from "../../faunadb/helpers/utils";
import Logging from "../logging/Loggings";
import {useQuery as useReactQuery, useMutation as useReactMutation, useQueryClient} from 'react-query';


const Course = (props) => {
  const courseId = props.match.params.id;
  const { handleSubmit, control } = useForm();
  const { queries, client } = useFaunaContext();
  const queryClient = useQueryClient();
  const {data, loading} = useReactQuery(['course',courseId], async()=> await getCourse(client, courseId));

  const {data: allStudents, loading: allStudentsLoading} = useReactQuery('allStudents', async()=> await getAllStudents(client, 9999));

  const onSubmit = (data) => {
    queries.course
      .ADD_USER_TO_COURSE(client, data.student.value, courseId)
      .then((response) => {
        if (response.errors) {
          printErrorMessageFromFQL(response.errors);
        } else {
          toast.success("Student Added");
          queries.log.logging(
            client,
            "Student",
            data.student.value,
            "Course",
            courseId,
            `join by admin`
          );
          queryClient.invalidateQueries(['course',courseId]);
        }
      });
  };

  if (!data || !allStudents?.data) return <></>;
  const { classes, name, students } = data;
  const studentsOption = [];
  if (allStudents.data) {
    allStudents.data.map((student) =>
      studentsOption.push({
        label: `${student.email} ${student.phone_number} ${student.name}`,
        value: student._id,
      })
    );
  }

  return (
    <>
      {!loading && (
        <>
          <DashboardCard title={`${name}`}>
            <InfoTable
              cols={[
                { Headers: "Teacher", accessor: "teacher.name" },
                { Headers: "Price", accessor: "price" },
                { Headers: "Quota", accessor: "quota" },
                { Headers: "Weekday", accessor: "weekday" },
              ]}
              data={[data]}
            />
          </DashboardCard>
          <DashboardCard title="Classes">
            <ClassList classes={classes.data} />
          </DashboardCard>
          <DashboardCard title="Students">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <Label>Add Student</Label>
                <Controller
                  as={<ReactSelect styles={SelectStyle} />}
                  options={studentsOption}
                  name="student"
                  isClearable
                  control={control}
                  defaultValue=""
                />
                {!allStudentsLoading && <InputField type="submit" />}
              </div>
            </Form>
            <StudentList
              students={students.data}
              courseId={courseId}
              updateGetCourseCache={()=>queryClient.invalidateQueries(['course',courseId])}
            />
          </DashboardCard>
          <DashboardCard title="Logging">
            <Logging type="Course" id={courseId} />
          </DashboardCard>
        </>
      )}
    </>
  );
};

export default Course;
