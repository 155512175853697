
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Toggle from 'rsuite/Toggle';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { generate_performance_term_report, make_performance_term_show_on_dashboard } from '../../../faunadb/queries/performance';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import usePerformanceTermDetail from '../hooks/usePerformanceTermDetail';
import { Button } from 'rsuite';

function jsonToCsv(data) {
    const headers = Object.keys(data[0]);
    const rows = [headers.join(",")];

    data.forEach((obj) => {
        console.log(obj);
        obj.application_start_date = moment(obj.application_start_date.value).local().format('YYYY-MM-DD HH:mm');
        obj.application_end_date = moment(obj.application_end_date.value).local().format('YYYY-MM-DD HH:mm');
        obj.rehearsal_date = moment(obj.rehearsal_date.value).local().format('YYYY-MM-DD HH:mm');
        obj.performance_date = moment(obj.performance_date.value).local().format('YYYY-MM-DD HH:mm');
        obj.guest_entrance_date = moment(obj.guest_entrance_date.value).local().format('YYYY-MM-DD HH:mm');
        const row = headers.map((header) => obj[header]);
        rows.push(row.join(","));
    });

    return rows.join("\n");
}

export default function Detail() {
    const id = useParams().id;
    const { client } = useFaunaContext();
    const data = usePerformanceTermDetail(client, id);
    const queryClient = useQueryClient();
    const showOnDashboardMutation = useMutation(
        make_performance_term_show_on_dashboard,
        {
            onSuccess: () => {
                toast.success('Updated Successfully');
                queryClient.invalidateQueries(['performance-term', id]);
            },
        }
    );
    return (
        <>
            {data?.isSuccess && (
                <>
                    <div>
                        <div>ID: {data?.data?.ref.value.id}</div>
                        <div>Name: {data?.data?.data.name}</div>
                        <div>
                            Application Start Date:{' '}
                            {moment(data?.data?.data.application_start_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')}
                        </div>
                        <div>
                            Application End Date:{' '}
                            {moment(data?.data?.data.application_end_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')}
                        </div>
                        <div>
                            Show on dashboard:{' '}
                            <Toggle
                                onChange={(value) => {
                                    showOnDashboardMutation.mutate({
                                        client: client,
                                        id: data?.data?.ref.value.id,
                                        show_on_dashboard: value,
                                    });
                                }}
                                defaultValue={data?.data?.data.show_on_dashboard}
                                defaultChecked={data?.data?.data.show_on_dashboard}
                                value={data?.data?.data.show_on_dashboard}
                            ></Toggle>
                        </div>
                    </div>
                </>
            )}
            <div style={{ marginTop: '20px' }}>
                <Button onClick={async () => {
                    let report = await generate_performance_term_report(client, id);

                    let csvURL = window.URL.createObjectURL(new Blob([jsonToCsv(report)], { type: 'text/csv' }));
                    let tempLink = document.createElement("a");
                    tempLink.href = csvURL;
                    tempLink.setAttribute("download", `${id}.csv`);
                    tempLink.click();
                }
                }>
                    Export performance term summary
                </Button>
            </div>
        </>
    );
}
