import gql from "graphql-tag";

import faunadb, { Time, Update } from "faunadb";
const q = faunadb.query;
const { Call,Paginate,Match,Index,Lambda,Get,Var,Map ,Ref,Collection} = q;
export const GET_GENERAL_SETTING = gql`
  query($id: ID!) {
    findGeneralSettingByID(id: $id) {
      _id
      normal_apply_start_date
      normal_apply_end_date
      vip_apply_start_date
      vip_apply_end_date
    }
  }
`;

export const getGeneralSetting = async (client, id) => {
  console.log(id);
  try {
    let response = await client.query(
      Get(Ref(Collection("GeneralSetting"), id))
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
}

export const updateGeneralSetting = async (client,{vipStartDate,vipEndDate,normalStartDate,normalEndDate}) => {
  try{
    let response = await client.query(
      Update(
        Ref(Collection("GeneralSetting"),process.env.REACT_APP_PERIOD_SETTING,),
        {
          data:{
            vip_apply_start_date:Time(vipStartDate.toISOString()),
            vip_apply_end_date:Time(vipEndDate.toISOString()),
            normal_apply_start_date:Time(normalStartDate.toISOString()),
            normal_apply_end_date:Time(normalEndDate.toISOString()),
          }
        }
      )
    
    )
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
}

export const ALL_DISCOUNT_GROUP = gql`
  query($size: Int, $cursor: String) {
    allDiscountGroup(_size: $size, _cursor: $cursor) {
      data {
        _ts
        _id
        name
        discount_series
      }
      before
      after
    }
  }
`;

export const getAllDiscountGroups = async (client) => {
  try {
    let response = await client.query(
      Map(
        Paginate(Match(Index("allDiscountGroup")), { size: 999 }),
        Lambda('discountGroupRef', Get(Var('discountGroupRef')))
      )
    );
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
};

export const UPDATE_GENERAL_SETTING = gql`
  mutation(
    $id: ID!
    $vipStartDate: Time
    $vipEndDate: Time
    $normalStartDate: Time
    $normalEndDate: Time
  ) {
    updateGeneralSetting(
      id: $id
      data: {
        vip_apply_start_date: $vipStartDate
        vip_apply_end_date: $vipEndDate
        normal_apply_start_date: $normalStartDate
        normal_apply_end_date: $normalEndDate
      }
    ) {
      _id
    }
  }
`;
