import React, { useEffect, useState } from 'react';
import { editClassDate, GET_CLASS, getClass } from '../../faunadb/queries/classquery';
import DashboardCard from '../../components/card/DashboardCard';
import { useFaunaContext } from '../../providers/FaunaProvider';
import TicketCard from './components/TicketCard';
import { ListContainer } from '../../styles/common/ListContainer';
import { toast } from 'react-toastify';
import InfoTable from '../../components/table/InfoTable';
import { Button, DateRangePicker, Table } from 'rsuite';
import moment from 'moment';
import {useQuery as useReactQuery, useMutation, useQueryClient} from 'react-query';

const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
  return (
    <Table.Cell {...props} style={{ padding: '6px' }}>
      <Button
        appearance="link"
        onClick={() => {
          onClick(rowData.id);
        }}
      >
        {rowData.status === 'EDIT' ? 'Save' : 'Edit'}
      </Button>
    </Table.Cell>
  );
};

const ClassPage = (props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [tickets, SetTickets] = useState([]);
  const classId = props.match.params.id;

  const {data:data2} = useReactQuery(['class',classId],async ()=> await getClass(client,classId));

  const { queries, client } = useFaunaContext();
  const queryClient = useQueryClient();
  
  const getClassAttendanceTickets = async () => {
    const response = await queries.classroom.get_class_attendance_tickets(
      client,
      classId
    );
    if (response?.data) {
      SetTickets(response.data);
    }
    return response;
  };

  const takeAttendance = async (ticketId) => {
    const attendanceResponse = await queries.classroom.attempt_class(
      client,
      ticketId
    );
    console.log(attendanceResponse);
    if (attendanceResponse?.data) {
      getClassAttendanceTickets();
      toast.success('Student Attended!');
    }
  };

  const helpDropIn = async (ticketId, studentID) => {
    const response = await queries.classroom.help_drop_in(
      client,
      ticketId,
      studentID
    );
    console.log(response);
    if (response?.data) {
      getClassAttendanceTickets();
      toast.success('Student Dropped In!');
    }
    if (response?.errors) {
      toast.error(response.errors[0].code);
    }
  };

  const editDate = (data) => {
    setShowEdit(!showEdit);
  };
  useEffect(() => {
    getClassAttendanceTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId]);
  if (data2) {
    return (
      <>
        <DashboardCard title={`${data2?.course.name}`}>
          <InfoTable
            cols={[
              { Headers: 'Teacher', accessor: 'teacher.name' },
              { Headers: 'Price', accessor: 'price' },
              { Headers: 'Quota', accessor: 'quota' },
              { Headers: 'Weekday', accessor: 'weekday' },
            ]}
            data={[data2?.course]}
          />
        </DashboardCard>
        <DashboardCard title={'Class Detail'}>
          <Table
            virtualized
            style={{ width: '100%' }}
            autoHeight
            data={[data2]}
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Class id</Table.HeaderCell>
              <Table.Cell>{(c) => c._id}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.Cell>
                {(c) => moment(c.date.value).format('YYYY-MM-DD HH:mm')}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>End date</Table.HeaderCell>
              <Table.Cell>
                {(c) => moment(c.end_date.value).format('YYYY-MM-DD HH:mm')}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Edit Time</Table.HeaderCell>
              <ActionCell onClick={editDate} />
            </Table.Column>
          </Table>
          {showEdit && (
            <DateRangePicker
              block
              format="yyyy-MM-dd HH:mm"
              onOk={(value) => {
                editClassDate(
                  client,
                  classId,
                  moment(value[0]).seconds(0).milliseconds(0).toISOString(),
                  moment(value[1]).seconds(0).milliseconds(0).toISOString()
                ).then((response) => {
                  if (response.data) {
                    toast.success('Updated');
                    // refetch();
                    queryClient.invalidateQueries(['class',classId]);
                    getClassAttendanceTickets();
                  }
                });
              }}
            />
          )}
        </DashboardCard>
        <DashboardCard title="Attendees">
          <ListContainer>
            {tickets.length > 0 &&
              tickets.map((ticket) => (
                <TicketCard
                  key={ticket.ticket_id}
                  ticket={ticket}
                  takeAttendance={takeAttendance}
                  helpDropIn={helpDropIn}
                ></TicketCard>
              ))}
          </ListContainer>
        </DashboardCard>
      </>
    );
  }
  return <></>;
};

export default ClassPage;
