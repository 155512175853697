import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form, InputField, Label, SelectStyle } from '../../../styles/common/Form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import usePotentialPerformanceStudents from '../hooks/usePotentialPerformanceStudents';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import ReactSelect from 'react-select';
import usePerformanceDetail from '../hooks/usePerformanceDetail';
import { insert_student_to_performance } from '../../../faunadb/queries/performance';

function InsertStudentToPerformanceForm() {
    const { handleSubmit, control } = useForm();
    const performanceId = useParams().id;

    const { client } = useFaunaContext();
    const { data: performanceDetail } = usePerformanceDetail(client, performanceId);
    const { data: studentList = [] } = usePotentialPerformanceStudents(client);

    const queryClient = useQueryClient();
    const mutation = useMutation(insert_student_to_performance, {
        onError: (error) => {
            toast.error(error?.description);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["performance", performanceId]);
            queryClient.invalidateQueries(["performance", performanceId, "student-list"]);
            toast.success('Student added');
        },
    });

    const onSubmit = (value) => {
        if (!value?.student?.value?.value?.id) return;
        mutation.mutate(
            {
                client: client,
                performanceTermId: performanceDetail?.performance_term.ref.value.id,
                performanceDayId: performanceDetail?.performance_day.ref.value.id,
                performanceId: performanceId,
                studentId: value.student.value.value.id
            });
    };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Label>Student</Label>
                {studentList.length > 0 && (<Controller
                    as={
                        <ReactSelect styles={SelectStyle} />
                    }
                    options={studentList}
                    name="student"
                    isClearable
                    control={control}
                    defaultValue=""
                />)}
                {studentList.length > 0 && <InputField type="submit" />}
            </Form>
        </>
    );
}

export default InsertStudentToPerformanceForm;
