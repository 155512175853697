import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Form,
  InputField,
  Label,
  SelectStyle,
} from '../../../styles/common/Form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CreatableSelect from 'react-select/creatable';

import {
  allClassrooms,
  createClassroom,
} from '../../../faunadb/queries/classroom';
import { createTerm } from '../../../faunadb/queries/term';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFaunaContext } from '../../../providers/FaunaProvider';

const TermForm = ({ close }) => {
  const { register, handleSubmit, watch, control } = useForm();
  const {client} = useFaunaContext();
  const queryClient = useQueryClient();
  const {data} = useQuery(['allClassrooms'], async()=>await allClassrooms(client));

  const createTermMutation = useMutation(term=>createTerm(client,term),{
    onSuccess: () => {
      queryClient.invalidateQueries('allTerms');
      close();
    },
    onCompleted: () => {
      close();
    }
  })
  let options = [];
  if (data?.data) {
    for (let i = 0; i < data.data.length; i++) {
      options.push({
        value: data.data[i]._id,
        label: data.data[i].name,
      });
    }
  }
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const breakStartDate = watch('breakStartDate');
  const breakEndDate = watch('breakEndDate');

  const onSubmit = (data) => {
    if (data.classroom === '') return;
    createTermMutation.mutate({
        name: data.name,
        start_date: data.startDate.toLocaleDateString('fr-CA'),
        end_date: data.endDate.toLocaleDateString('fr-CA'),
        break_start_date: data?.breakStartDate
          ? data?.breakStartDate.toLocaleDateString('fr-CA')
          : null,
        break_end_date: data?.breakEndDate
          ? data?.breakEndDate.toLocaleDateString('fr-CA')
          : null,
        classroom: data.classroom.value,
    })
  };

  const createClassroomMutation = useMutation(classroom=>createClassroom(client,classroom),{
    onSuccess: () => {
      queryClient.invalidateQueries('allClassrooms');
      toast.success('Classroom created!');
    }
  })
  

  const handleCreate = (newValue) => {
    createClassroomMutation.mutate({
      name: newValue,
    })
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Label>Term Name</Label>
      <InputField name="name" ref={register({ required: true })} />
      <Label>Start Date</Label>
      <Controller
        as={
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            selected={startDate ? startDate : new Date()}
          />
        }
        control={control}
        name="startDate"
        className="input"
        placeholderText="Select date"
        defaultValue={new Date()}
      />
      <Label>End Date</Label>
      <Controller
        as={
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            selected={endDate ? endDate : new Date()}
          />
        }
        control={control}
        name="endDate"
        className="input"
        placeholderText="Select date"
        defaultValue={new Date()}
      />

      <Label>Break Start Date</Label>
      <Controller
        as={
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            selected={breakStartDate}
          />
        }
        control={control}
        name="breakStartDate"
        className="input"
        placeholderText="Select date"
        defaultValue={null}
      />

      <Label>Break End Date</Label>
      <Controller
        as={
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            selected={breakEndDate}
          />
        }
        control={control}
        name="breakEndDate"
        className="input"
        placeholderText="Select date"
        defaultValue={null}
      />

      <Label>Classroom</Label>
      <Controller
        as={
          <CreatableSelect styles={SelectStyle} onCreateOption={handleCreate} />
        }
        options={options}
        name="classroom"
        isClearable
        control={control}
        defaultValue=""
      />
      <Label>Submit</Label>
      {!createTermMutation.isLoading && <InputField type="submit" />}
    </Form>
  );
};
export default TermForm;
