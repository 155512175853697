import faunadb, { ToDouble } from 'faunadb';
const q = faunadb.query;

export const get_performance_term = async (client, id) => {
    try {
        let response = await client.query(
            q.Get(q.Ref(q.Collection('PerformanceTerm'), id))
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const get_performance_day = async (client, id) => {
    try {
        let response = await client.query(
            q.Get(q.Ref(q.Collection('PerformanceDay'), id))
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const get_performance = async (client, id) => {
    try {
        let response = await client.query(
            flattenPerformance(q.Get(q.Ref(q.Collection('Performance'), id)))
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const get_performance_terms = async (client) => {
    try {
        let response = await client.query(
            q.Filter(q.Select(["data"], q.Map(
                q.Paginate(q.Documents(q.Collection("PerformanceTerm"))),
                q.Lambda("x", q.Get(q.Var("x")))
            ))
                , q.Lambda("x", q.Equals(q.Select(["data", "deleted"], q.Var("x")), false))

            )

        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
};

export const delete_performance_term = async ({ client, id }) => {
    try {
        let response = await client.query(
            q.Update(q.Ref(q.Collection('PerformanceTerm'), id), {
                data: {
                    deleted: true,
                },
            })
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const delete_performance_day = async ({ client, id }) => {
    try {
        let response = await client.query(
            q.Update(q.Ref(q.Collection('PerformanceDay'), id), {
                data: {
                    deleted: true,
                },
            })
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const delete_performance = async ({ client, id }) => {
    try {
        let response = await client.query(
            q.Update(q.Ref(q.Collection('Performance'), id), {
                data: {
                    deleted: true,
                },
            })
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const make_performance_term_show_on_dashboard = async ({ client, id, show_on_dashboard }) => {
    try {
        let response = await client.query(
            q.Update(q.Ref(q.Collection('PerformanceTerm'), id), {
                data: {
                    show_on_dashboard: show_on_dashboard,
                },
            })
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const create_performance_term = async (client, parameters) => {
    try {
        let response = await client.query(
            q.Create(q.Collection('PerformanceTerm'), {
                data: {
                    name: parameters.name,
                    application_start_date: q.Time(
                        parameters.dates[0].toISOString().slice(0, 19) + '+00:00'
                    ),
                    application_end_date: q.Time(
                        parameters.dates[1].toISOString().slice(0, 19) + '+00:00'
                    ),
                    deleted: false,
                    show_on_dashboard: false
                },
            })
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
};

export const create_performance_day = async ({ client, termId, rehearsalDate, guestEntranceDate, performanceDate }) => {
    try {
        let response = await client.query(
            q.Create(q.Collection('PerformanceDay'), {
                data: {
                    performance_term: q.Ref(q.Collection('PerformanceTerm'), termId),
                    rehearsal_date: q.Time(rehearsalDate.toISOString().slice(0, 19) + '+00:00'),
                    guest_entrance_date: q.Time(guestEntranceDate.toISOString().slice(0, 19) + '+00:00'),
                    performance_date: q.Time(performanceDate.toISOString().slice(0, 19) + '+00:00'),
                    deleted: false
                },
            })
        );
        return response;
    }
    catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const create_performance = async ({ client, termId, dayId, classroomId, quota, name }) => {
    try {
        let response = await client.query(
            q.Create(q.Collection('Performance'), {
                data: {
                    performance_term: q.Ref(q.Collection('PerformanceTerm'), termId),
                    performance_day: q.Ref(q.Collection('PerformanceDay'), dayId),
                    classroom: q.Ref(q.Collection('Classroom'), classroomId),
                    quota: parseInt(quota),
                    price: q.ToDouble(50),
                    performance_name: name,
                    deleted: false
                },
            })
        );
        return response;
    }
    catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const get_performance_days_by_term = async (client, termId) => {
    try {
        let response = await client.query(
            q.Filter(q.Select(["data"], q.Map(
                q.Paginate(q.Match(q.Index('performanceDay_performance_term_by_performanceTerm'), q.Ref(q.Collection('PerformanceTerm'), termId))),
                q.Lambda("x", q.Get(q.Var("x")))
            ))
                , q.Lambda("x", q.Equals(q.Select(["data", "deleted"], q.Var("x")), false))

            )
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const get_performances_by_pday = async (client, dayId) => {
    try {
        let response = await client.query(
            q.Map(
                q.Filter(q.Select(["data"], q.Map(
                    q.Paginate(q.Match(q.Index('performanceDay_performances_by_performanceDay'), q.Ref(q.Collection('PerformanceDay'), dayId))),
                    q.Lambda("x", q.Get(q.Var("x")))
                ))
                    , q.Lambda("x", q.Equals(q.Select(["data", "deleted"], q.Var("x")), false))

                )
                , q.Lambda("document",
                    flattenPerformance(q.Var("document"))
                )
            )
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const get_potential_performance_students = async (client) => {
    try {
        let response = await client.query(
            q.Select(["data"], q.Map(
                q.Paginate(q.Match(q.Index("allStudents")), { size: 9999 }),
                q.Lambda(
                    "document",
                    q.Let(
                        { student: q.Var("document") },
                        {
                            value: q.Select(["ref"], q.Get(q.Var("document"))),

                            label: q.Concat(q.Select(["data", "email"], q.Get(q.Var("document"))))
                        }
                    )
                )
            ))
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

// create a function that get studentlist from performance using index performance_performers_by_performance
export const get_performance_students = async (client, performanceId) => {
    try {
        let response = await client.query(
            q.Select(["data"], q.Map(
                q.Paginate(q.Match(q.Index("performance_performers_by_performance"), q.Ref(q.Collection('Performance'), performanceId))),
                q.Lambda(
                    "document",
                    q.Let(
                        { student: q.Var("document") },
                        {
                            ref: q.Select(["ref"], q.Get(q.Var("document"))),
                            student: q.Select(["data"], q.Get(q.Var("document")))
                        }
                    )
                )
            ))
        );
        return response;
    } catch (error) {
        return JSON.parse(error.requestResult.responseRaw);
    }
}

export const kick_student_out_from_performance = async ({ client, performanceId, studentId }) => {
    // First check if it exist in Index performance_performers_by_performance_and_student , if exist delete this document, if not return the student is not in this performance
    let response = await client.query(
        q.If(
            q.Exists(
                q.Match(
                    q.Index('performance_performers_by_performance_and_student'),
                    q.Ref(q.Collection('Performance'), performanceId),
                    q.Ref(q.Collection('Student'), studentId)
                )
            ),
            // if exists, delete this document
            q.Do(q.Delete(
                q.Select(["ref"], q.Get(
                    q.Match(
                        q.Index('performance_performers_by_performance_and_student'),
                        q.Ref(q.Collection('Performance'), performanceId),
                        q.Ref(q.Collection('Student'), studentId)
                    )
                ))
            ),
                // update performance quota by +1
                q.Update(q.Ref(q.Collection('Performance'), performanceId), {
                    data: {
                        quota: q.Add(q.Select(["data", "quota"], q.Get(q.Ref(q.Collection('Performance'), performanceId))), 1)
                    }
                }),

            ),
            // if not exists, return the student is not in this performance
            q.Abort("Student is not in this performance")
        ));
    return response;
}

export const insert_student_to_performance = async ({ client, performanceTermId, performanceDayId, performanceId, studentId }) => {
    // try {
    let response = await client.query(
        q.Do(
            // check if performance's quota if >= 1
            q.If(
                q.Equals(q.Select(["data", "quota"], q.Get(q.Ref(q.Collection('Performance'), performanceId))), 0),
                // if quota is 0, return error
                q.Abort("Performance quota is 0"),
                true
            ),
            //check if a student have <= 3 records , first use Index(performance_performers_by_student) wth studentId input to get all document and filter record which contain performanceDayId
            q.If(
                q.GTE(q.Count(q.Select(["data"], q.Filter(
                    q.Map(
                        q.Paginate(
                            q.Match(
                                q.Index("performance_performers_by_student"),
                                q.Ref(q.Collection("Student"), studentId)
                            )
                        ),
                        q.Lambda("x", q.Get(q.Var("x")))
                    ),

                    q.Lambda("x",
                        q.And(q.Equals(
                            q.Select(["data", "performance_day"], q.Var("x")),
                            q.Ref(q.Collection("PerformanceDay"), performanceDayId)
                        ), q.Equals(
                            q.Select(["data", "deleted"], q.Var("x")),
                            false
                        ))
                    )
                ))), 3),
                q.Abort("One Student can only apply at most 3 performances in one day"),
                true

            ),

            // check if student+performance exist in performance_performers using Index("performance_performers_by_performance_and_student")
            q.If(
                q.Exists(
                    q.Match(
                        q.Index('performance_performers_by_performance_and_student'),
                        q.Ref(q.Collection('Performance'), performanceId),
                        q.Ref(q.Collection('Student'), studentId)
                    )
                ),
                // if exists, return error
                q.Abort("Student already in performance"),
                // if not exists, insert student+performance to performance_performers using Index("performance_performers_by_performance_and_student")
                true),
            q.Create(q.Collection('performance_performers'), {
                data: {
                    performance_term: q.Ref(q.Collection('PerformanceTerm'), performanceTermId),
                    performance_day: q.Ref(q.Collection('PerformanceDay'), performanceDayId),
                    performanceID: q.Ref(q.Collection('Performance'), performanceId),
                    studentID: q.Ref(q.Collection('Student'), studentId),
                    paid: ToDouble(0)
                }
            }),
            // update performance quota by -1
            q.Update(q.Ref(q.Collection('Performance'), performanceId), {
                data: {
                    quota: q.Subtract(q.Select(["data", "quota"], q.Get(q.Ref(q.Collection('Performance'), performanceId))), 1)
                }
            })
        ))
    return response;
    // } catch (error) {
    //     return JSON.parse(error.requestResult.responseRaw);
    // }
}

export const generate_performance_term_report = async (client, performanceTermID) => {
    let response = await client.query(
        q.Select(
            ["data"],
            q.Map(
                q.Paginate(
                    q.Match(
                        q.Index("performance_performers_by_performance_term"),
                        q.Ref(q.Collection("PerformanceTerm"), performanceTermID)
                    ),
                    { size: 9999 }
                ),
                q.Lambda("document", q.Let(
                    {
                        record: q.Get(q.Var("document")),
                        performance_term: q.Get(q.Select(["data", "performance_term"], q.Var("record"))),
                        performance_day: q.Get(q.Select(["data", "performance_day"], q.Var("record"))),
                        performance: q.Get(q.Select(["data", "performanceID"], q.Var("record"))),
                        student: q.Get(q.Select(["data", "studentID"], q.Var("record"))),
                        classroom: q.Get(q.Select(["data", "classroom"], q.Var("performance")))
                    },
                    {
                        performer_ticket_id: q.Select(["ref", "id"], q.Var("record")),
                        performance_term_name: q.Select(["data", "name"], q.Var("performance_term")),
                        application_start_date: q.Select(["data", "application_start_date"], q.Var("performance_term")),
                        application_end_date: q.Select(["data", "application_end_date"], q.Var("performance_term")),
                        rehearsal_date: q.Select(["data", "rehearsal_date"], q.Var("performance_day")),
                        classroom: q.Select(["data", "name"], q.Get(q.Select(["data", "classroom"], q.Var("performance")))),
                        guest_entrance_date: q.Select(["data", "guest_entrance_date"], q.Var("performance_day")),
                        performance_date: q.Select(["data", "performance_date"], q.Var("performance_day")),
                        performance_name: q.Select(["data", "performance_name"], q.Var("performance")),
                        price: q.Select(["data", "price"], q.Var("performance")),
                        quota: q.Select(["data", "quota"], q.Var("performance")),
                        student_paid: q.Select(["data", "paid"], q.Var("record")),
                        student_id: q.Select(["ref", "id"], q.Var("student"), ""),
                        student_email: q.Select(["data", "email"], q.Var("student"), ""),
                        student_name: q.Select(["data", "name"], q.Var("student"), ""),
                        student_phone_number: q.Select(["data", "phone_number"], q.Var("student"), ""),
                        student_vip: q.Select(["data", "vip"], q.Var("student"), ""),
                        student_vvip: q.Select(["data", "vvip"], q.Var("student"), ""),

                    }
                )
                )
            )
        )
    )
    return response;

}
function flattenPerformance(performance) {
    return q.Let({
        performance_term_ref: q.Select(["ref"], q.Get(q.Select(["data", "performance_term"], performance))),
        performance_term: q.Select(["data"], q.Get(q.Select(["data", "performance_term"], performance))),
        performance_day_ref: q.Select(["ref"], q.Get(q.Select(["data", "performance_day"], performance))),
        performance_day: q.Select(["data"], q.Get(q.Select(["data", "performance_day"], performance))),
        classroom_ref: q.Select(["ref"], q.Get(q.Select(["data", "classroom"], performance))),
        classroom: q.Select(["data"], q.Get(q.Select(["data", "classroom"], performance))),
        performance_name: q.Select(["data", "performance_name"], performance),
        price: q.Select(["data", "price"], performance),
        quota: q.Select(["data", "quota"], performance)
    },
        {
            ref: q.Select(["ref"], performance),
            performance_term: { ref: q.Var("performance_term_ref"), data: q.Var("performance_term") },
            performance_day: { ref: q.Var("performance_day_ref"), data: q.Var("performance_day") },
            classroom: { ref: q.Var("classroom_ref"), data: q.Var("classroom") },
            performance_name: q.Var("performance_name"),
            price: q.Select(["data", "price"], performance),
            quota: q.Var("quota")
        })
}