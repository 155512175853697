import React from "react";
import DashboardCard from "../../components/card/DashboardCard";
import TeacherList from "./components/TeacherList";
import {useQuery} from 'react-query'
import { useFaunaContext } from "../../providers/FaunaProvider";
import { getAllTeachers } from "../../faunadb/queries/teacher";
const Teacher = () => {
  const {client} = useFaunaContext();
  const {data} = useQuery('allTeachers', async()=>await getAllTeachers(client));
  return (
    <>
      <DashboardCard title="Teacher List">
        {data?.data && <TeacherList teachers={data?.data}></TeacherList>}
      </DashboardCard>
    </>
  );
};

export default Teacher;
