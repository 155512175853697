import faunadb from "faunadb";
const q = faunadb.query;
const { Call } = q;

const logging = async (
  client,
  source_type,
  source_id,
  target_type,
  target_id,
  action
) => {
  try {
    let response = await client.query(
      Call(q.Function("add_log"), [
        source_type,
        source_id,
        target_type,
        target_id,
        action,
      ])
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const get_system_logs = async (client, size, before, after) => {
  try {
    let response = await client.query(
      Call(q.Function("get_system_logs"), [size, before, after])
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const get_my_latest_logs = async (client, type, id, size, before, after) => {
  try {
    let response = await client.query(
      Call(q.Function("get_my_latest_logs"), [type, id, size, before, after])
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const exportItem = { logging, get_system_logs, get_my_latest_logs };
export default exportItem;
