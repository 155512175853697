import faunadb from 'faunadb';
import { teacher } from '../../signals/teacher';
const q = faunadb.query;
const { Call, Select, Get, CurrentIdentity } = q;

const login = async (client, email, password, setClient) => {
  try {
    let response = await client.query(
      Call(q.Function('login_teacher'), {
        email: email,
        password: password,
      })
    );
    console.log(response?.data?.teacher);
    teacher.value = response?.data?.teacher || {};
    setClient(new faunadb.Client({ secret: response.secret }));
    sessionStorage.setItem('admin_id', response.instance.value.id);
    sessionStorage.setItem('access_token', response.secret);
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

export const isSuperAdmin = async (client) => {
  try {
    let response = await client.query(
      Select(["data", "super_admin"], Get(CurrentIdentity()))
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
}


const exportItem = { login, isSuperAdmin };
export default exportItem;
