import DashboardCard from '../../components/card/DashboardCard';
import DayDetail from './components/performance-day-detail';
import PerformanceList from './components/performance-list';
import PerformanceForm from './create-performance';

export default function PerformanceDayDetail() {
    return (
        <>

            <DashboardCard title={'Performance Day Detail'}>
                <DayDetail />
            </DashboardCard>
            <DashboardCard title={'Performances'}>
                <PerformanceForm />
                <PerformanceList />
            </DashboardCard>
        </>
    );
}
