import React from 'react';
import CourseForm from '../term/components/CourseForm';

const CreateCourse = ({ match }) => {
  const termId = match.params.id;
  console.log(termId);
  return <CourseForm term={termId} />;
};

export default CreateCourse;
