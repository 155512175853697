import React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'rsuite';
import Swal from 'sweetalert2';
import { printErrorMessageFromFQL } from '../../../faunadb/helpers/utils';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { Label } from '../../../styles/common/Form';

const ReleaseAllQuota = () => {
  const { queries, client } = useFaunaContext();

  function handleAction() {
    Swal.fire({
      title: 'Do you want to release all quota?',
      showCancelButton: true,
      confirmButtonText: 'Release',
      denyButtonText: `Back`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        queries.course
          .RELEASE_ALL_QUOTA(client)
          .then((response) => {
            if (response.errors) {
              printErrorMessageFromFQL(response.errors);
            } else {
              toast.success('Quota released');
              Swal.fire('Quota released!', '', 'success');
            }
          })
          .finally(() => {});
      }
    });
  }
  return (
    <>
      <Label>將當前term 的 所有剩餘 quota 全部做catch up 堂</Label>
      <Button onClick={handleAction}>Release</Button>
    </>
  );
};

export default ReleaseAllQuota;
