import React, { useState } from 'react';
import styled from 'styled-components';
import {
  AiOutlineHome,
  AiOutlinePoweroff,
  AiOutlineTool,
  AiOutlineMessage,
} from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';
import { GiHamburgerMenu, GiGraduateCap, GiBookCover, GiJumpingRope } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { teacher } from '../../signals/teacher';
import { useFaunaContext } from '../../providers/FaunaProvider';
import { useIsSuperAdmin } from '../../hooks/useIsSuperAdmin.js';

const NavItemDes = styled.p`
  position: absolute;
  visibility: hidden;
  transition: opacity 0.5s ease-out;
  opacity: 0;
  color: #fff;
`;

const HamBurgerContainer = styled.div`
  width: 75px;
  height: 75px;
  transition: width 0.5s;
  display: flex;
  justify-content: space-evenly;
  background-color: black;
  padding: 0;
  border: none;
  align-items: center;
  color: #fff;
  cursor: pointer;
`;
const NavItem = styled(Link)`
  width: 75px;
  height: 75px;
  transition: width 0.5s;
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    background-color: #96969636;
  }
  & svg {
    color: #fff;
    height: 30px;
    width: 30px;
  }
  &:last-child {
    margin-top: auto;
  }
`;

const NavBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 75px;
  height: 100%;
  background-color: black;
  transition: width 0.5s;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media (max-width: 576px) {
    flex-direction: row;
    width: 100% !important;
    height: 75px;
    top: calc(100% - 75px);
    justify-content: space-evenly;
  }

  & {
    width: ${(props) => (props.expand ? '150px' : '75px')};
  }
  & ${HamBurgerContainer} {
    width: ${(props) => (props.expand ? '150px' : '75px')};
  }
  & ${NavItem} {
    width: ${(props) => (props.expand ? '150px' : '75px')};
  }

  & ${NavItemDes} {
    visibility: ${(props) => (props.expand ? 'visible' : 'hidden')};
    position: ${(props) => (props.expand ? 'unset' : 'absolute')};
    opacity: ${(props) => (props.expand ? 1 : 0)};
  }
`;

const Navbar = () => {
  const [expand, setExpand] = useState(false);
  const { client } = useFaunaContext();
  const { data } = useIsSuperAdmin(client);
  return (
    <NavBackground expand={expand}>
      <HamBurgerContainer onClick={() => setExpand(!expand)}>
        <GiHamburgerMenu></GiHamburgerMenu>
      </HamBurgerContainer>
      {data && (
        <>
          <NavItem to="/dashboard">
            <AiOutlineHome />
            <NavItemDes>Home</NavItemDes>
          </NavItem>
          <NavItem to="/notification">
            <AiOutlineMessage />
            <NavItemDes>Notification</NavItemDes>
          </NavItem>
          <NavItem to="/student">
            <BsPeople></BsPeople>
            <NavItemDes>Student</NavItemDes>
          </NavItem>
          <NavItem to="/teacher">
            <GiGraduateCap></GiGraduateCap>
            <NavItemDes>Teacher</NavItemDes>
          </NavItem>
          <NavItem to="/settings">
            <AiOutlineTool />
            <NavItemDes>Settings</NavItemDes>
          </NavItem>
          <NavItem to="/performance">
            <GiJumpingRope />
            <NavItemDes>Perfomance</NavItemDes>
          </NavItem>
        </>
      )}
      <NavItem to="/attend">
        <GiBookCover />
        <NavItemDes>Attend</NavItemDes>
      </NavItem>
      <NavItem
        to="/"
        onClick={() => {
          sessionStorage.clear();
          teacher.value = {};
        }}
      >
        <AiOutlinePoweroff />
        <NavItemDes>Logout</NavItemDes>
      </NavItem>
    </NavBackground>
  );
};

export default Navbar;
