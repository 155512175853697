import React, { createContext, useState, useContext } from "react";
import faunadb from "faunadb";
import queries from "../faunadb/queries/index";

const FaunaContext = createContext({});

export const useFaunaContext = () => {
  const context = useContext(FaunaContext);
  if (!context) {
    throw new Error("FaunaContext not exists");
  }
  return context;
};

const FaunaProvider = (props) => {
  const [client, setClient] = useState(
    new faunadb.Client({
      secret: sessionStorage.getItem("access_token")
        ? sessionStorage.getItem("access_token")
        : process.env.REACT_APP_FAUNA_DB_KEY,
    })
  );

  const value = {
    queries,
    client,
    setClient,
  };

  return (
    <FaunaContext.Provider value={value} {...props}></FaunaContext.Provider>
  );
};

export default FaunaProvider;
