import gql from 'graphql-tag';
import faunadb from 'faunadb';
import { Select } from '../helpers/utils';
const q = faunadb.query;
const {
  Call,
  Do,
  Let,
  Get,
  Ref,
  Collection,
  Var,
  Create,
  // Select,
  Time,
  Map,
  If,
  ContainsPath,
  Filter,
  Index,
  Lambda,
  Match,
  Paginate,
  And,
  Equals,
  Repeat,
  Update,
  GT,
  ToDouble
} = q;

const ADD_USER_TO_COURSE = async (client, studentId, courseId) => {
  try {
    let response = await client.query(
      Let(
        { course: Get(Ref(Collection('Course'), courseId)) },
        Do(
          Call(q.Function('add_user_to_course'), [studentId, courseId]),
          Create(Collection('Order'), {
            data: {
              student: Ref(Collection('Student'), studentId),
              course: Ref(Collection('Course'), courseId),
              term: Select(['data', 'term'], Var('course')),
              price: ToDouble(0.0),
              date: Time('now'),
            },
          })
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const getCourse = async (client, courseId) => {
  try {
    let response = await client.query(
      Let(
        {
          id: courseId,
          course: Get(Ref(Collection("Course"), Var("id"))),
          teacher: Get(Select(["data", "teacher"], Var("course"))),
          students: Map(
            Paginate(
              Match(
                Index("course_students_by_course"),
                Ref(Collection("Course"), Var("id"))
              )
            ),
            Lambda("studentRef", Let(
              {
                student: Get(Var("studentRef"))
              },
              {
                _id: Select(["ref", "id"], Var("student")),
                name: Select(["data", "name"], Var("student")),
                email: Select(["data", "email"], Var("student")),
                name: Select(["data", "name"], Var("student")),
                credit: Select(["data", "credit"], Var("student")),
                phone_number: Select(["data", "phone_number"], Var("student")),
              }))
          ),
          classes: Map(
            Paginate(
              Match(
                Index("class_course_by_course"),
                Ref(Collection("Course"), Var("id"))
              )
            ),
            Lambda(
              "classRef",
              Let(
                {
                  classs: Get(Var("classRef")),
                  attendees: Map(
                    Paginate(
                      Match(
                        Index("class_attendees_by_class"),
                        Ref(Collection("Class"), Select(["ref", "id"], Var("classs")))
                      )
                    ),
                    Lambda("attendeeRef", Get(Var("attendeeRef")))
                  )
                },
                {
                  _id: Select(["ref", "id"], Var("classs")),
                  date: Select(["data", "date"], Var("classs")),
                  end_date: Select(["data", "end_date"], Var("classs")),
                  attendees: Var("attendees")
                }
              )
            )
          )
        },
        {
          _id: Select(["ref", "id"], Var("course")),
          name: Select(["data", "name"], Var("course")),
          quota: Select(["data", "quota"], Var("course")),
          price: Select(["data", "price"], Var("course")),
          weekday: Select(["data", "weekday"], Var("course")),
          teacher: {
            _id: Select(["ref", "id"], Var("teacher")),
            name: Select(["data", "name"], Var("teacher")),
            email: Select(["data", "email"], Var("teacher"))
          },
          students: Var("students"),
          classes: Var("classes")
        }
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const CREATE_COURSE = gql`
  mutation (
    $name: String!
    $teacher: ID
    $quota: Int!
    $price: Float!
    $weekday: Int!
    $term: ID
    $classes: [ClassInput]
    $start_at: String
    $duration: Int
    $discount_group: ID
  ) {
    createCourse(
      data: {
        name: $name
        term: { connect: $term }
        teacher: { connect: $teacher }
        quota: $quota
        price: $price
        weekday: $weekday
        classes: { create: $classes }
        start_at: $start_at
        duration: $duration
        discount_group: { connect: $discount_group }
        deleted: false
      }
    ) {
      _id
    }
  }
`;

export const createCourse = async (client, {
  name,
  price,
  quota,
  teacher,
  term,
  weekday,
  classes,
  start_at,
  duration,
  discount_group,
}) => {
  try {
    let response = await client.query(
        Let({
          course: 
            Create(Collection("Course"), {
              data: {
                name: name,
                term: Ref(Collection("Term"), term),
                teacher: Ref(Collection("Teacher"), teacher),
                quota: quota,
                price: ToDouble(price),
                weekday: weekday,
                start_at: start_at,
                duration: duration,
                discount_group: Ref(Collection("DiscountGroup"), discount_group),
                deleted: false,
              },
            }
          ),
        },
        Map(classes, Lambda("c",
            Create(Collection("Class"), {
              data: {
                date: Time(Select(["date"], Var("c"))),
                end_date: Time(Select(["end_date"], Var("c"))),
                course:Select(["ref"], Var("course"))
              }
            }))
        ))
    );
    return response;
  } catch (error) {
    console.log(error);
    return JSON.parse(error.requestResult.responseRaw);
  }
}

const DELETE_COURSE = async (client, courseId) => {
  try {
    let response = await client.query(
      Call(q.Function('delete_course'), courseId)
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const DELETE_USER_FROM_COURSE = async (client, courseId, studentId) => {
  try {
    let response = await client.query(
      Call(q.Function('delete_user_from_course'), [studentId, courseId])
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const RELEASE_ALL_QUOTA = async (client) => {
  try {
    let response = await client.query(
      Map(
        Filter(
          Map(
            Paginate(Match(Index('allCourses')), { size: 9999 }),
            Lambda('c', Get(Var('c')))
          ),
          Lambda(
            'c',
            Let(
              {
                quota: Select(['data', 'quota'], Var('c')),
                deleted: If(
                  ContainsPath(['data', 'deleted'], Var('c')),
                  Select(['data', 'deleted'], Var('c')),
                  true
                ),
                activeTerm: Select(
                  ['data', 'status'],
                  Get(Select(['data', 'term'], Var('c')))
                ),
              },
              And(
                GT(Var('quota'), 0),
                Equals(Var('deleted'), false),
                Equals(Var('activeTerm'), 1)
              )
            )
          )
        ),
        Lambda(
          'filtered_courses',
          Let(
            {
              course: Select(['ref'], Var('filtered_courses')),
              classes: Select(
                ['data'],
                Map(
                  Paginate(
                    Match(
                      Index('class_course_by_course'),
                      Select(['ref'], Var('filtered_courses'))
                    ),
                    { size: 9999 }
                  ),
                  Lambda('class', Var('class'))
                )
              ),
              quota: Select(['data', 'quota'], Var('filtered_courses')),
              array: Call(
                'SplitString',
                Repeat(
                  'None|',
                  Select(['data', 'quota'], Var('filtered_courses'))
                ),
                '|'
              ),
            },
            Do(
              Map(
                Var('classes'),
                Lambda(
                  'tempC',
                  Map(
                    Var('array'),
                    Lambda(
                      'a',
                      Create(Collection('AttendanceTicket'), {
                        data: {
                          course: Select(['ref'], Var('filtered_courses')),
                          session: Select(['ref'], Get(Var('tempC'))),
                          student: 'None',
                          date: Select(['data', 'date'], Get(Var('tempC'))),
                          attended: false,
                          catchup: false,
                        },
                      })
                    )
                  )
                )
              ),
              Update(Select(['ref'], Var('filtered_courses')), {
                data: {
                  quota: 0,
                },
              })
            )
          )
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const exportItems = {
  CREATE_COURSE,
  DELETE_COURSE,
  ADD_USER_TO_COURSE,
  DELETE_USER_FROM_COURSE,
  RELEASE_ALL_QUOTA,
};

export default exportItems;
