import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form, Label } from '../../../styles/common/Form';
import { toast } from 'react-toastify';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { Button, DateRangePicker } from 'rsuite';

const ResetPromoteVIP = () => {
  const { handleSubmit, control } = useForm();
  const { queries, client } = useFaunaContext();
  const onSubmit = async (payload) => {
    console.log(payload);
    if (payload.vipStartDate !== undefined) {
      const r = await queries.student.reset_and_promote_vip(
        client,
        payload.vipStartDate[0].getTime() * 1000,
        payload.vipStartDate[1].getTime() * 1000
      );
      if (r) return toast.success('VIP updated');
      return toast.error('Error!');
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>
          先重設所有用戶到普通用戶，再將設定期間內有3堂 order＊就會promote 上
          vip， 4堂就vip 加 8粒金credit{' '}
        </Label>
        <Label>From:</Label>
        <Controller
          as={<DateRangePicker format="yyyy-MM-dd HH:mm" />}
          control={control}
          name="vipStartDate"
          className="input"
        />
        <Label>To:</Label>
        <Button type="submit">Reset and promote</Button>
      </Form>
    </>
  );
};

export default ResetPromoteVIP;
