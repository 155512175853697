import faunadb from "faunadb";
const q = faunadb.query;
const { Call } = q;

const get_my_orders = async (client, id, size, before, after) => {
  try {
    let response = await client.query(
      Call(q.Function("get_my_orders"), [id, size, before, after])
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const exportItem = { get_my_orders };
export default exportItem;
