import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form, InputField, Label } from '../../styles/common/Form';
import { create_performance_day } from '../../faunadb/queries/performance';
import { useMutation, useQueryClient } from 'react-query';
import { useFaunaContext } from '../../providers/FaunaProvider';
import { DatePicker } from 'rsuite';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

function PerformanceDayForm() {
    const { handleSubmit, control } = useForm();
    const termId = useParams().id;

    const { client } = useFaunaContext();
    const queryClient = useQueryClient();
    const mutation = useMutation(create_performance_day, {
        onSuccess: () => {
            queryClient.invalidateQueries(["performance-days", termId]);
            toast.success('Performance Day Created');
        },
    });

    const onSubmit = (value) => {
        mutation.mutate({ client: client, termId: termId, ...value });
    };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Label>Rehearsal Date</Label>
                <Controller
                    as={<DatePicker style={{ zIndex: 999 }} format="yyyy-MM-dd HH:mm" />}
                    control={control}
                    name="rehearsalDate"
                />
                <Label>Guest Entrance Date</Label>
                <Controller
                    as={<DatePicker style={{ zIndex: 999 }} format="yyyy-MM-dd HH:mm" />}
                    control={control}
                    name="guestEntranceDate"
                />
                <Label>Performance Date</Label>
                <Controller
                    as={<DatePicker style={{ zIndex: 999 }} format="yyyy-MM-dd HH:mm" />}
                    control={control}
                    name="performanceDate"
                />

                <Label>Submit</Label>
                <InputField type="submit" />
            </Form>
        </>
    );
}

export default PerformanceDayForm;
