import { useQuery } from "@apollo/react-hooks";
import React from "react";
import DashboardCard from "../../components/card/DashboardCard";
import { GET_TEACHER } from "../../faunadb/queries/teacher";
import { ListContainer } from "../../styles/common/ListContainer";
import CourseCard from "../../pages/term/components/CourseCard";

const TeacherDetail = (props) => {
  const teacherId = props.match.params.id;
  const { data, loading } = useQuery(GET_TEACHER, {
    variables: {
      id: teacherId,
    },
  });
  return (
    <>
      {!loading && data && (
        <>
          <DashboardCard title={`${data.findTeacherByID.name}`}></DashboardCard>
          <DashboardCard title="Course List">
            <ListContainer>
              {data &&
                data.findTeacherByID.courses.data.map(
                  (course) =>
                    !course.term.deleted && (
                      <CourseCard course={course} key={course._id}></CourseCard>
                    )
                )}
            </ListContainer>
          </DashboardCard>
        </>
      )}
    </>
  );
};

export default TeacherDetail;
