import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import Toggle from "react-toggle";
import "../../styles/common/toggle.css";

const Table = styled.table`
  width: 100%;
  color: white;
`;

const TableDataCell = styled.td`
  text-align: center;
`;

const getWeekday = (weekday) => {
  if (weekday === 1) return "Monday";
  if (weekday === 2) return "Tuesday";
  if (weekday === 3) return "Wednesday";
  if (weekday === 4) return "Thursday";
  if (weekday === 5) return "Friday";
  if (weekday === 6) return "Saturday";
  if (weekday === 7) return "Sunday";
  return weekday;
};

// const getStatus = (status) => {
//   if (status === 0) return "Unpublished";
//   if (status === 1) return "Published";
//   return "Unknown";
// };

const renderCell = (cell) => {
  if (cell.column.id === "weekday") {
    return (
      <TableDataCell {...cell.getCellProps()}>
        {getWeekday(cell.value)}
      </TableDataCell>
    );
  }
  if (cell.column.id === "status") {
    return (
      <TableDataCell {...cell.getCellProps()}>
        <Toggle
          defaultChecked={Boolean(cell.value)}
          onChange={cell.column.onChange}
          value={String(cell.value)}
        />
      </TableDataCell>
    );
  }
  return (
    <TableDataCell {...cell.getCellProps()}>
      {cell.render("Cell")}
    </TableDataCell>
  );
};

const InfoTable = ({ cols, data }) => {
  const table = useTable({ columns: cols, data: data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = table;
  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.Headers}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => renderCell(cell))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default InfoTable;
