import React, { useEffect, useState } from 'react';
import { useFaunaContext } from '../../providers/FaunaProvider';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import moment from 'moment';

const Order = ({ studentId }) => {
  const { queries, client } = useFaunaContext();
  const [orders, setOrders] = useState([]);

  const get_my_oredrs = async () => {
    queries.order
      .get_my_orders(client, studentId, 50, null, null)
      .then((response) => {
        if (response.data) {
          setOrders(response.data);
        }
      });
  };

  useEffect(() => {
    get_my_oredrs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Table
        style={{
          color: 'white',
        }}
      >
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Term</Th>
            <Th>Course</Th>
            <Th>Price</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.length > 0 &&
            orders.map((order) => {
              return (
                <Tr key={`${order.date}`}>
                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {moment(order.date.value).toLocaleString().substring(0, 24)}
                  </Td>

                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {order.term.name}
                  </Td>

                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <a
                      style={{ color: 'white' }}
                      href={`/course/${order.course._id}`}
                    >
                      {order.course.name}
                    </a>
                  </Td>

                  <Td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    ${order.price}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </>
  );
};

export default Order;
