import React from "react";
import { Wrapper } from "../../../styles/common/Wrapper";
import TeacherCard from "./TeacherCard";
import { CardButton } from "../../../styles/common/Card";
import PopupContainer from "../../../styles/common/PopupContainer";
import TeacherForm from "./TeacherForm";
import Popup from "reactjs-popup";
import { ListContainer } from "../../../styles/common/ListContainer";

const TeacherList = ({ teachers }) => {
  return (
    <Wrapper>
      <Popup
        trigger={
          <CardButton style={{ maxWidth: "150px" }}>Create Teacher</CardButton>
        }
        modal
      >
        {(close) => (
          <PopupContainer>
            <TeacherForm close={close}></TeacherForm>
          </PopupContainer>
        )}
      </Popup>
      <ListContainer>
        {teachers.length > 0
          ? teachers
              .slice()
              .filter(function (t) {
                return !t.data.deleted;
              })
              .map((teacher) => (
                <TeacherCard teacher={teacher} key={teacher.ref.value.id} />
              ))
          : "No teacher yet"}
      </ListContainer>
    </Wrapper>
  );
};

export default TeacherList;
