import { Button, Table } from "rsuite";
import usePerformanceTerm from "../hooks/usePerformanceTerm";
import moment from "moment";
import Swal from "sweetalert2";
import { useMutation, useQueryClient } from "react-query";
import { useFaunaContext } from "../../../providers/FaunaProvider";
import { delete_performance_term, kick_student_out_from_performance } from "../../../faunadb/queries/performance";
import { useHistory } from "react-router-dom";
import usePerformanceStudentList from "../hooks/usePerformanceStudentList";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function PerformanceStudentList() {
    const history = useHistory();
    const { client } = useFaunaContext();
    const performanceId = useParams().id;
    const { data, isLoading } = usePerformanceStudentList(client, performanceId);
    const queryClient = useQueryClient();
    const mutation = useMutation(kick_student_out_from_performance, {
        onSuccess: () => {
            queryClient.invalidateQueries(["performance", performanceId]);
            queryClient.invalidateQueries(["performance", performanceId, "student-list"]);
        }
    })

    return <>
        <Table
            virtualized
            style={{ width: '100%', zIndex: 0 }}
            autoHeight
            data={data || []}
            loading={isLoading}>

            <Table.Column width={50}>
                <Table.HeaderCell>No.</Table.HeaderCell>
                <Table.Cell>{(_, index) => index + 1}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.Cell dataKey="student.name" />
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.Cell dataKey="student.email" />
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell>Phone number</Table.HeaderCell>
                <Table.Cell dataKey="student.phone_number" />
            </Table.Column>
            <Table.Column flexGrow={1}>
                <Table.HeaderCell>VVIP</Table.HeaderCell>
                <Table.Cell>{({ student }) => student?.vvip ? "Yes" : "No"}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
                <Table.HeaderCell>VIP</Table.HeaderCell>
                <Table.Cell>{({ student }) => student?.vip ? "Yes" : "No"}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} fixed="right">
                <Table.HeaderCell>Action</Table.HeaderCell>

                <Table.Cell>
                    {(student) => {
                        return (
                            <span>
                                <Button
                                    appearance="link"
                                    onClick={() => { history.push(`/student/${student.ref.value.id}`) }}
                                >
                                    View{' '}
                                </Button>
                            </span>
                        );
                    }}
                </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} fixed="right">
                <Table.HeaderCell>Action</Table.HeaderCell>

                <Table.Cell>
                    {(student) => {
                        return (
                            <span>
                                <Button
                                    appearance="link"
                                    onClick={() => { mutation.mutate({ client: client, performanceId: performanceId, studentId: student.ref.value.id }) }}
                                >
                                    Kick
                                </Button>
                            </span>
                        );
                    }}
                </Table.Cell>
            </Table.Column>
        </Table>
    </>
}