import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from '../../pages/auth/Login';
import ClassPage from '../../pages/class/ClassPage';
import Course from '../../pages/course/Course';
import CreateCourse from '../../pages/course/CreateCourse';
import Dashboard from '../../pages/dashboard/Dashboard';
import SettingsPage from '../../pages/settings/SettingsPage';
import Student from '../../pages/student/Student';
import StudentDetail from '../../pages/student/StudentDetail';
import Teacher from '../../pages/teacher/Teacher';
import TeacherDetail from '../../pages/teacher/TeacherDetail';
import AttendPage from '../../pages/attend/AttendPage';
import Term from '../../pages/term/Term';
import { BackgroundContainer } from '../../styles/common/BackgroundContainer';
import Navbar from '../navbar/Navbar';
import NotificationPage from '../../pages/notification/NotificationPage';
import PerformancePage from '../../pages/performance';
import PerformanceTermDetail from '../../pages/performance/[id]';
import PerformanceDayDetail from '../../pages/performance/[day-id]';
import PerformanceDetail from '../../pages/performance/[performance-id]';

const MyRouter = () => {
  return (
    <Router>
      <Navbar></Navbar>
      <Switch>
        <Route path="/" exact component={Login} />
        <BackgroundContainer>
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/student" exact component={Student} />
          <Route path="/notification" exact component={NotificationPage} />
          <Route
            path="/student/:id"
            render={(props) => <StudentDetail {...props} />}
          />
          <Route path="/teacher" exact component={Teacher} />
          <Route
            path="/teacher/:id"
            render={(props) => <TeacherDetail {...props} />}
          />
          <Route path="/term/:id" render={(props) => <Term {...props} />} />
          <Route
            path="/term-create-course/:id"
            render={(props) => <CreateCourse {...props} />}
          />
          <Route path="/course/:id" render={(props) => <Course {...props} />} />
          <Route
            path="/class/:id"
            render={(props) => <ClassPage {...props} />}
          />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/performance" component={PerformancePage} />
          <Route path="/performance-term/:id" component={PerformanceTermDetail} />
          <Route path="/performance-day/:id" component={PerformanceDayDetail} />
          <Route path="/performance-detail/:id" component={PerformanceDetail} />
          <Route path="/attend" component={AttendPage} />
          <div style={{ height: '100px' }}></div>
        </BackgroundContainer>
      </Switch>
    </Router>
  );
};

export default MyRouter;
