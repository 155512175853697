import styled from "styled-components";

const PopupContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  color: #fff;
  border-radius: 10px;
  width: 50%;
  min-width: 350px;
  padding: 20px;
  height: auto;
`;
export default PopupContainer;
