
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import usePerformanceDayDetail from '../hooks/usePerformanceDayDetail';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function DayDetail() {
    const id = useParams().id;
    const history = useHistory();
    const { client } = useFaunaContext();
    const data = usePerformanceDayDetail(client, id);

    return (
        <>
            {data?.isSuccess && (
                <>
                    <div>
                        <Button onClick={() => { history.push(`/performance-term/${data?.data?.data.performance_term.value.id}`) }}>Back to Term</Button>

                        <div>ID: {data?.data?.ref.value.id}</div>
                        <div>
                            Rehearsal Date:{' '}
                            {moment(data?.data?.data.rehearsal_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')}
                        </div>
                        <div>
                            Guest Entrance Date:{' '}
                            {moment(data?.data?.data.guest_entrance_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')}
                        </div>
                        <div>
                            Performance Date:{' '}
                            {moment(data?.data?.data.performance_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
