/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useFaunaContext } from "../../../providers/FaunaProvider";
import { BsDownload } from "react-icons/bs";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_TERM_REPORT } from "../../../faunadb/queries/term";
import { getWeekday, toCSV } from "../../../faunadb/helpers/utils";

const TermSummary = ({ termId }) => {
  const { queries, client } = useFaunaContext();
  const [summary, setSummary] = useState(null);
  const csvData = [];

  const [getReport] = useLazyQuery(GET_TERM_REPORT, {
    variables: {
      id: termId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      data.findTermByID.courses.data.map((course) => {
        course.students.data.map((student) =>
          csvData.push([
            course.name,
            getWeekday(course.weekday),
            course.start_at,
            course.teacher.name,
            student.name,
            student.email,
            student.phone_number,
          ])
        );
      });
      let csv = new Blob(
        [
          toCSV(
            csvData,
            [
              "Course Name",
              "Course Weekday",
              "Course Start At",
              "Teacher",
              "Student Name",
              "Student Email",
              "Student Phone Number",
            ],
            ",",
            ""
          ),
        ],
        {
          type: "text/csv",
        }
      );
      let csvURL = window.URL.createObjectURL(csv);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", `${data.findTermByID.name}.csv`);
      tempLink.click();
    },
  });

  async function getTermSummary() {
    queries.term
      .GET_TERM_SUMMARY(client, termId)
      .then((response) => setSummary(response));
  }
  useEffect(() => {
    getTermSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {summary && (
        <div style={{ color: "white" }}>
          <div>
            Number of Unique Student: {summary.number_of_unique_student}
          </div>
          <div>Courses Applied: {summary.count}</div>
          <div>Total Credit: {summary.total_amount}</div>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              getReport();
            }}
          >
            <BsDownload size={30}></BsDownload>
          </div>
        </div>
      )}
    </>
  );
};

export default TermSummary;
