import gql from 'graphql-tag';
import faunadb, { GT } from 'faunadb';
import { Select } from '../helpers/utils';
const q = faunadb.query;
const {
  Call,
  Update,
  Ref,
  Collection,
  Map,
  Paginate,
  Range,
  Match,
  Index,
  Get,
  // Select,
  Lambda,
  Var,
  CurrentIdentity,
  If,
  Do,
  Let,
  ContainsField
} = q;



export const getAllStudents = async (client, size = 9999, cursor = null) => {
  try {
    let response = await client.query(
      Let(
        {
          students: Map(
            Paginate(
              Match(Index("allStudents")),
              { size: size}
            ),
            Lambda(
              "studentRef",
              Let(
                {
                  student: Get(Var("studentRef")),
                },
                {
                  _id: Select(["ref", "id"], Var("student")),
                  email: Select(["data", "email"], Var("student")),
                  name: Select(["data", "name"], Var("student")),
                  credit: Select(["data", "credit"], Var("student")),
                  vip: Select(["data", "vip"], Var("student")),
                  vvip: Select(["data", "vvip"], Var("student")),
                  phone_number: Select(["data", "phone_number"], Var("student")),
                  catch_up_credit_tier_0: Select(["data", "catch_up_credit_tier_0"], Var("student")),
                  catch_up_credit_tier_1: Select(["data", "catch_up_credit_tier_1"], Var("student")),
                  catch_up_credit_tier_2: Select(["data", "catch_up_credit_tier_2"], Var("student")),
                  catch_up_credit_tier_3: Select(["data", "catch_up_credit_tier_3"], Var("student")),
                }
              )
            )
          )
        },
        {
          data: Select(["data"], Var("students")),
        }
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const GET_STUDENT = gql`
  query ($id: ID!) {
    findStudentByID(id: $id) {
      _id
      email
      name
      credit
      vip
      vvip
      phone_number
      catch_up_credit_tier_0
      catch_up_credit_tier_1
      catch_up_credit_tier_2
      catch_up_credit_tier_3
      courses {
        data {
          teacher {
            name
            icon
          }
          _id
          name
          quota
          price
          weekday
          start_at
          term {
            name
            start_date
            end_date
          }
        }
      }
    }
  }
`;

export const getStudent = async (client, id) => {
  try {
    let response = await client.query(
      Let(
        {
          student: Get(Ref(Collection("Student"), id)),
          courses: Map(
            Paginate(
              Match(
                Index("course_students_by_student"),
                Ref(Collection("Student"), id)
              )
            ),
            Lambda(
              "courseRef",
              Let(
                {
                  course: Get(Var("courseRef")),
                  teacher: Get(Select(["data", "teacher"], Var("course"))),
                  term: Get(Select(["data", "term"], Var("course"))),
                  classroom: Get(Select(["data", "classroom"], Var("term")))
                },
                {
                  _id: Select(["ref", "id"], Var("course")),
                  name: Select(["data", "name"], Var("course")),
                  quota: Select(["data", "quota"], Var("course")),
                  price: Select(["data", "price"], Var("course")),
                  weekday: Select(["data", "weekday"], Var("course")),
                  start_at: Select(["data", "start_at"], Var("course")),
                  teacher: {
                    name: Select(["data", "name"], Var("teacher")),
                  },
                  term: {
                    _id: Select(["ref", "id"], Var("term")),
                    name: Select(["data", "name"], Var("term")),
                    start_date: Select(["data", "start_date"], Var("term")),
                    end_date: Select(["data", "end_date"], Var("term")),
                    classroom: Select(["data", "name"], Var("classroom"))
                  }
                }
              )
            )
          ),
          orders: Map(
            Paginate(
              Match(
                Index("order_student_by_student"),
                Ref(Collection("Student"), id)
              )
            ),
            Lambda(
              "orderRef",
              Let(
                {
                  order: Get(Var("orderRef")),
                  course: Get(Select(["data", "course"], Var("order"))),
                  teacher: Get(Select(["data", "teacher"], Var("course"))),
                  term: Get(Select(["data", "term"], Var("course"))),
                  classroom: Get(Select(["data", "classroom"], Var("term")))
                },
                {
                  canceled: If(
                    ContainsField("canceled", Select(["data"], Var("order"))),
                    Select(["data", "canceled"], Var("order")),
                    false
                  ),
                  price: Select(["data", "price"], Var("order")),
                  date: Select(["data", "date"], Var("order")),
                  course: {
                    _id: Select(["ref", "id"], Var("course")),
                    name: Select(["data", "name"], Var("course")),
                    quota: Select(["data", "quota"], Var("course")),
                    price: Select(["data", "price"], Var("course")),
                    weekday: Select(["data", "weekday"], Var("course")),
                    start_at: Select(["data", "start_at"], Var("course")),
                    teacher: {
                      name: Select(["data", "name"], Var("teacher")),
                    },
                    term: {
                      _id: Select(["ref", "id"], Var("term")),
                      name: Select(["data", "name"], Var("term")),
                      start_date: Select(["data", "start_date"], Var("term")),
                      end_date: Select(["data", "end_date"], Var("term")),
                      classroom: Select(["data", "name"], Var("classroom"))
                    }
                  }
                }
              )
            )
          )
        },
        {
          _id: Select(["ref", "id"], Var("student")),
          email: Select(["data", "email"], Var("student")),
          name: Select(["data", "name"], Var("student")),
          credit: Select(["data", "credit"], Var("student")),
          vip: Select(["data", "vip"], Var("student")),
          vvip: Select(["data", "vvip"], Var("student")),
          phone_number: Select(["data", "phone_number"], Var("student")),
          catch_up_credit_tier_0: Select(
            ['data', 'catch_up_credit_tier_0'],
            Var('student')
          ),
          catch_up_credit_tier_1: Select(
            ['data', 'catch_up_credit_tier_1'],
            Var('student')
          ),
          catch_up_credit_tier_2: Select(
            ['data', 'catch_up_credit_tier_2'],
            Var('student')
          ),
          catch_up_credit_tier_3: Select(
            ['data', 'catch_up_credit_tier_3'],
            Var('student')
          ),
          courses: Var("courses"),
          orders: Var("orders")
        }
      )
    );
    return response;
  } catch (error) {
    console.log(error);
    return JSON.parse(error.requestResult.responseRaw);
  }
};



export const UPDATE_STUDENT = gql`
  mutation (
    $id: ID!
    $name: String
    $email: String!
    $credit: Float
    $vip: Boolean
    $catch_up_credit_tier_0: Int
    $catch_up_credit_tier_1: Int
    $catch_up_credit_tier_2: Int
  ) {
    updateStudent(
      id: $id
      data: {
        name: $name
        email: $email
        credit: $credit
        vip: $vip
        catch_up_credit_tier_0: $catch_up_credit_tier_0
        catch_up_credit_tier_1: $catch_up_credit_tier_1
        catch_up_credit_tier_2: $catch_up_credit_tier_2
      }
    ) {
      _id
      name
      email
      credit
      vip
      catch_up_credit_tier_0
      catch_up_credit_tier_1
      catch_up_credit_tier_2
    }
  }
`;

export const updateStudent = async (client, {id, name, email, credit, vip, catch_up_credit_tier_0, catch_up_credit_tier_1, catch_up_credit_tier_2}) => {
  try {
    let response = await client.query(
      Update(
        Ref(Collection('Student'), id),
        {
          data: {
            name: name,
            email: email,
            credit: credit,
            vip: vip,
            catch_up_credit_tier_0: catch_up_credit_tier_0,
            catch_up_credit_tier_1: catch_up_credit_tier_1,
            catch_up_credit_tier_2: catch_up_credit_tier_2
          }
        }
      )
    );
    return response;
  } catch (error) {
    console.log(error);
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const reset_all_catch_up_credit = async (client) => {
  try {
    let response = await client.query(
      Map(
        Paginate(Match(Index('allStudents')), { size: 9999 }),
        Lambda(
          'student',
          Update(Var('student'), {
            data: {
              catch_up_credit_tier_0: 0,
              catch_up_credit_tier_1: 0,
              catch_up_credit_tier_2: 0,
            },
          })
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const reset_and_promote_vip = async (client, start_date, end_date) => {
  try {
    console.log(start_date, end_date);
    let r = await client.query(
      Map(
        Paginate(
          Range(Match(Index('all_orders_with_ts')), start_date, end_date),
          { size: 9999 }
        ),
        Lambda(
          'order',
          If(
            GT(Select(['data', 'price'], Get(Select([1], Var('order')))), 0),
            Select(
              'id',
              Select(['data', 'student'], Get(Select([1], Var('order'))))
            ),
            null
          )
        )
      )
    );

    const counts = {};
    r.data.forEach(function (x) {
      if (x) {
        counts[x] = (counts[x] || 0) + 1;
      }
    });
    console.log(counts);
    let finalVipList = [];
    let finalVVipList = [];
    for (let key of Object.keys(counts)) {
      if (counts[key] >= 3) {
        finalVipList.push(key);
      }
      if (counts[key] >= 4) {
        finalVVipList.push(key);
      }
    }
    console.log(finalVipList);
    console.log(finalVVipList);

    //reset all vip to general

    let resetVIPResponse = await client.query(
      Map(
        Paginate(Match(Index('allStudents')), { size: 9999 }),
        Lambda(
          'student',
          Update(Var('student'), { data: { vip: false, vvip: false } })
        )
      )
    );

    console.log(resetVIPResponse);

    let promoteVIPResponse = await client.query(
      Map(
        finalVipList,
        Lambda(
          'student_id',
          Do(
            Update(Ref(Collection('Student'), Var('student_id')), {
              data: { vip: true, vvip: true, catch_up_credit_tier_0: 8 },
            }),

            Call(q.Function('add_log'), [
              Select(['collection', 'id'], CurrentIdentity()),
              Select(['id'], CurrentIdentity()),
              'Student',
              Var('student_id'),
              'Promote to VIP',
            ])
          )
        )
      )
    );

    console.log(promoteVIPResponse);

    let assign8FreeCatchUpCredit = await client.query(
      Map(
        finalVVipList,
        Lambda(
          'student_id',
          Do(
            Update(Ref(Collection('Student'), Var('student_id')), {
              data: { catch_up_credit_tier_2: 8 },
            }),

            Call(q.Function('add_log'), [
              Select(['collection', 'id'], CurrentIdentity()),
              Select(['id'], CurrentIdentity()),
              'Student',
              Var('student_id'),
              'Add 8 🥇 to',
            ])
          )
        )
      )
    );

    console.log(assign8FreeCatchUpCredit);

    return true;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const exportItems = {
  reset_all_catch_up_credit,
  reset_and_promote_vip,
};

export default exportItems;
