import { Button } from "rsuite";
import { toCSV } from "../../../faunadb/helpers/utils";

function convertToArray(array) {
    return array.map(item => {
        return Object.values(item)
    })
}

export default function ExportVIPStudentListButton({ students }) {
    function exportCSV() {
        const csvData = students.filter(student => student.vip || student.vvip)
        let csv = new Blob(
            [
                toCSV(
                    convertToArray(csvData),
                    [
                        "ID",
                        "Email",
                        "Name",
                        "Credit",
                        "VIP",
                        "VVIP",
                        "Phone Number",
                        "Catch Up Credit Tier 0",
                        "Catch Up Credit Tier 1",
                        "Catch Up Credit Tier 2",
                        "Catch Up Credit Tier 3",

                    ],
                    ",",
                    ""
                ),
            ],
            {
                type: "text/csv",
            }
        );
        let csvURL = window.URL.createObjectURL(csv);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `VIP_Student_List.csv`);
        tempLink.click();
    }
    return <Button onClick={exportCSV} appearance="primary">Export VIP List</Button>
}