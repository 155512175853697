import styled from 'styled-components';

export const Form = styled.form`
  & input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 10px;
    background: black;
  }
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;
export const InputField = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
`;
export const Textarea = styled.textarea`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
`;
export const Label = styled.label`
  line-height: 2;
  text-align: left;
  display: block;
  margin-top: 10px;
  color: white;
  font-size: 1rem;
  font-weight: 200;
`;

export const SelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#999999' : null,
    color: 'black',
  }),
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'black',
  }),
};
