import { Button } from 'rsuite';
import DashboardCard from '../../components/card/DashboardCard';
import PerformanceDayList from './components/performance-day-list';
import Detail from './components/performance-term-detail';
import PerformanceDayForm from './create-day';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function PerformanceTermDetail() {
    const history = useHistory();
    return (
        <>

            <Button onClick={() => { history.push('/performance') }} >Back to Performance</Button>
            <DashboardCard title={'Performance Term Detail'}>
                <Detail></Detail>
            </DashboardCard>
            <DashboardCard title={'Performance Days'}>
                <PerformanceDayForm />
                <PerformanceDayList />
            </DashboardCard>
        </>
    );
}
