import React from "react";
import DashboardCard from "../../components/card/DashboardCard";
import TermList from "./components/TermList";
const Dashboard = () => {
  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
      >
        <DashboardCard title="Term">
          <TermList />
        </DashboardCard>
      </div>
    </>
  );
};

export default Dashboard;
