
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import usePerformanceDetail from '../hooks/usePerformanceDetail';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Detail() {
    const id = useParams().id;
    const history = useHistory();
    const { client } = useFaunaContext();
    const data = usePerformanceDetail(client, id);

    return (
        <>
            {data?.isSuccess && (
                <>
                    <div>
                        <div>
                            <Button style={{ marginTop: 12, marginBottom: 12 }} onClick={() => { history.push(`/performance-term/${data?.data?.performance_term.ref.value.id}`) }}>Back to Term {data?.data?.performance_term.data.name}</Button>
                        </div>
                        <div>
                            <Button style={{ marginTop: 12, marginBottom: 12 }} onClick={() => { history.push(`/performance-day/${data?.data?.performance_day.ref.value.id}`) }}>Back to List</Button>
                        </div>

                        <div>Performance ID: {data?.data?.ref.value.id}</div>
                        <div>Name: {data?.data?.performance_name}</div>
                        <div>Price: ${data?.data?.price}</div>
                        <div>Quota: {data?.data?.quota}</div>
                    </div>
                </>
            )}
        </>
    );
}
