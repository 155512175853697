import { useParams } from 'react-router-dom';
import usePerformanceDaysByTerm from '../hooks/usePerformanceDaysByTerm';
import { Button, Table } from 'rsuite';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { useMutation, useQueryClient } from 'react-query';
import { delete_performance_day } from '../../../faunadb/queries/performance';

export default function PerformanceDayList() {
    const id = useParams().id;
    const { data, isLoading } = usePerformanceDaysByTerm(id);
    const history = useHistory();

    const { client } = useFaunaContext();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation(delete_performance_day, {
        onSuccess: () => {
            queryClient.invalidateQueries(['performance-days', id]);
        }
    })

    return (
        <>
            <Table loading={isLoading} data={data || []}>
                <Table.Column width={50}>
                    <Table.HeaderCell>No.</Table.HeaderCell>
                    <Table.Cell>{(_, index) => index + 1}</Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Guest Entrance Date</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) =>
                            moment(rowData.data.guest_entrance_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')
                        }
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Rehearsal Date</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) =>
                            moment(rowData.data.rehearsal_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')
                        }
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Performance Date</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) =>
                            moment(rowData.data.performance_date.value)
                                .local()
                                .format('YYYY-MM-DD HH:mm')
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1} fixed="right">
                    <Table.HeaderCell>Action</Table.HeaderCell>

                    <Table.Cell>
                        {(term) => {
                            function handleAction() {
                                Swal.fire({
                                    title: 'Do you want to delete this term?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Delete',
                                    denyButtonText: `Don't save`,
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        deleteMutation.mutate({
                                            client: client,
                                            id: term.ref.value.id
                                        });
                                    }
                                });
                            }
                            return (
                                <span>
                                    <Button
                                        appearance="link"
                                        onClick={() => { history.push(`/performance-day/${term.ref.value.id}`) }}
                                    >
                                        {' '}
                                        View{' '}
                                    </Button>{' '}
                                    |
                                    <Button appearance="link" onClick={handleAction}>
                                        {' '}
                                        Remove{' '}
                                    </Button>
                                </span>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </>
    );
}
