import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form, InputField, Label } from '../../styles/common/Form';
import { create_performance_term } from '../../faunadb/queries/performance';
import { useMutation, useQueryClient } from 'react-query';
import { useFaunaContext } from '../../providers/FaunaProvider';
import { DateRangePicker } from 'rsuite';
import { toast } from 'react-toastify';


function PerformanceTermForm() {
    const { register, handleSubmit, watch, control } = useForm();
    const name = watch('name');
    const dates = watch('application_date');
    const { client } = useFaunaContext();
    const queryClient = useQueryClient();
    const mutation = useMutation(() => create_performance_term(client, { name: name, dates: dates }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('performance-term');
                toast.success('Performance Term Created');
            }
        });

    return <>
        <Form onSubmit={handleSubmit(mutation.mutate)}>
            <Label>Performance Term Name</Label>
            <InputField name="name" ref={register({ required: true })} />
            <Label>Application Start Date</Label>
            <Controller
                as={
                    <DateRangePicker
                        style={{ zIndex: 999 }}
                        format="yyyy-MM-dd HH:mm"
                    />
                }
                control={control}
                name="application_date"
            />

            <Label>Submit</Label>
            <InputField type="submit" />
        </Form>
    </>
}

export default PerformanceTermForm