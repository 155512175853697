import MyRouter from './components/router/MyRouter';
import AuthProvider from './providers/AuthProvider';
import FaunaProvider from './providers/FaunaProvider';
import GlobalStyle from './styles/GlobalStyle';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './faunadb/helpers/graphql_client';
import './styles/common/popup-anime.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'rsuite/dist/rsuite.min.css';
import { CustomProvider } from 'rsuite';
import { QueryClient, QueryClientProvider } from 'react-query';


const queryClient = new QueryClient()

function App() {
  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <CustomProvider theme="dark">
          <AuthProvider>
            <ApolloProvider client={client}>
              <FaunaProvider>
                <MyRouter />
              </FaunaProvider>
            </ApolloProvider>
          </AuthProvider>
        </CustomProvider>
      </QueryClientProvider>
      <ToastContainer />
    </>
  );
}

export default App;
