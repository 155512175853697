import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext({});

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("AuthContext not exists");
  }
  return context;
};

const AuthProvider = (props) => {
  const [loggedin, setLoggedin] = useState(false);
  const value = {
    loggedin,
    setLoggedin,
  };
  return (
    <>
      <AuthContext.Provider value={value} {...props}></AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
