import gql from 'graphql-tag';
import { Select } from '../helpers/utils';
import faunadb, { If,Exists, ContainsField, Get, Index, Lambda, Let, Map, Match, Paginate, Var, Update, Ref, Collection, Create, Date } from 'faunadb';
const q = faunadb.query;
const { Call } = q;

export const GET_TERM_SUMMARY = async (client, termId) => {
  try {
    let response = await client.query(
      Call(q.Function('get_term_summary'), termId)
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

export const GET_TERM_REPORT = gql`
  query ($id: ID!) {
    findTermByID(id: $id) {
      _id
      name
      courses {
        data {
          _id
          teacher {
            name
          }
          start_at
          name
          weekday
          students {
            data {
              name
              email
              phone_number
            }
          }
        }
      }
    }
  }
`;

export const GET_TERM = gql`
  query ($id: ID!) {
    findTermByID(id: $id) {
      _id
      name
      start_date
      end_date
      break_end_date
      break_start_date
      status
      deleted
      classroom {
        name
      }
      courses {
        data {
          _id
          name
          quota
          price
          weekday
          start_at
          deleted
          teacher {
            _id
            name
            email
            icon
          }
          discount_group {
            _id
            name
            discount_series
          }
        }
      }
    }
  }
`;

export const getTerm = async(client, id) => {
  try {
    let response = await client.query(
      Let(
        {
          term: Get(Ref(Collection('Term'), id)),
          classroom: Get(Select(['data', 'classroom'], Var('term'))),
          courses: Map(
            Paginate(Match(Index('course_term_by_term'), Ref(Collection('Term'), id))),
            Lambda('courseRef', 
              Let(
                {
                  course: Get(Var('courseRef')),
                  teacher: Get(Select(['data', 'teacher'], Var('course'))),
                  discount_group: Get(Select(['data', 'discount_group'], Var('course')))
                },
                {
                  _id: Select(['ref', 'id'], Var('course')),
                  name: Select(['data', 'name'], Var('course')),
                  quota: Select(['data', 'quota'], Var('course')),
                  price: Select(['data', 'price'], Var('course')),
                  weekday: Select(['data', 'weekday'], Var('course')),
                  start_at: Select(['data', 'start_at'], Var('course')),
                  deleted: If(
                  ContainsField("deleted", Select(["data"], Var("course"))),
                  Select(["data", "deleted"], Var("course")),
                  false
                ),
                  teacher: {
                    _id: Select(['ref', 'id'], Var('teacher')),
                    name: Select(['data', 'name'], Var('teacher')),
                    email: Select(['data', 'email'], Var('teacher'))
                  },
                  discount_group: {
                    _id: Select(['ref', 'id'], Var('discount_group')),
                    name: Select(['data', 'name'], Var('discount_group')),
                    discount_series: Select(['data', 'discount_series'], Var('discount_group'))
                  }
                }
              )
            )
          )
        },
        {
          _id: Select(['ref', 'id'], Var('term')),
          name: Select(['data', 'name'], Var('term')),
          start_date: Select(['data', 'start_date'], Var('term')),
          end_date: Select(['data', 'end_date'], Var('term')),
                break_start_date: If(
                  ContainsField("break_start_date", Select(["data"], Var("term"))),
                  Select(["data", "break_start_date"], Var("term")),
                  null
                ),
                break_end_date: If(
                  ContainsField("break_end_date", Select(["data"], Var("term"))),
                  Select(["data", "break_end_date"], Var("term")),
                  null
                ),
          status: Select(['data', 'status'], Var('term')),
          deleted: Select(['data', 'deleted'], Var('term')),
          classroom: {
            name: Select(['data', 'name'], Var('classroom'))
          },
          courses: Var('courses')
        }
      )
    );
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
}

export const ALL_TERMS = gql`
  query ($size: Int, $cursor: String) {
    allTerms(_size: $size, _cursor: $cursor) {
      data {
        _ts
        _id
        name
        start_date
        end_date
        break_start_date
        break_end_date
        status
        deleted
        classroom {
          name
        }
      }
      before
      after
    }
  }
`;

export const allTerms = async(client) => {
  try {
    let response = await client.query(
        Map(
          Paginate(Match(Index("allTerms")), { size: 999 }),
          Lambda(
            "termRef",
            Let(
              {
                term: Get(Var("termRef")),
                classroom: If(Exists(Select(["data", "classroom"], Var("term"))), Get(Select(["data", "classroom"], Var("term"))) , {data:{name:null}})
              },
              {
                _ts: Select(["ts"], Var("term")),
                _id: Select(["ref", "id"], Var("term")),
                name: Select(["data", "name"], Var("term")),
                start_date: Select(["data", "start_date"], Var("term")),
                end_date: Select(["data", "end_date"], Var("term")),
                break_start_date: If(
                  ContainsField("break_start_date", Select(["data"], Var("term"))),
                  Select(["data", "break_start_date"], Var("term")),
                  null
                ),
                break_end_date: If(
                  ContainsField("break_end_date", Select(["data"], Var("term"))),
                  Select(["data", "break_end_date"], Var("term")),
                  null
                ),
                status: Select(["data", "status"], Var("term")),
                deleted: Select(["data", "deleted"], Var("term")),
                classroom: {name: Select(["data","name"],Var("classroom"))}
              }
            )
          )
        )
      
    );
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
}

export const createTerm = async(client, {name,start_date,end_date,break_start_date,break_end_date,classroom}) => {
  try {
    let response = await client.query(
      Create(Collection('Term'), {
        data: {
          name: name,
          start_date: Date(start_date),
          end_date: Date(end_date),
          break_start_date: break_start_date? Date(break_start_date) :null,
          break_end_date: break_end_date? Date(break_end_date): null,
          classroom: Ref(Collection('Classroom'), classroom),
          status: 0,
          deleted: false
        }
      })
    );
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
}

export const UPDATE_TERM = gql`
  mutation (
    $id: ID!
    $name: String!
    $start_date: Date!
    $end_date: Date!
    $status: Int
    $deleted: Boolean
  ) {
    updateTerm(
      id: $id
      data: {
        name: $name
        start_date: $start_date
        end_date: $end_date
        status: $status
        deleted: $deleted
      }
    ) {
      _id
      name
      start_date
      end_date
      status
    }
  }
`;

export const updateTerm = async(client, {_id,name,start_date,end_date,status,deleted}) => {
  try {
    let response = await client.query(
      Update(
        Ref(Collection('Term'), _id),
        {
          data: {
            name: name,
            start_date: start_date,
            end_date: end_date,
            status: status,
            deleted: deleted
          }
        }
      )
    );
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error.requestResult.responseRaw);
  }
}

const exportItems = {
  ALL_TERMS,
  UPDATE_TERM,
  GET_TERM_SUMMARY,
};

export default exportItems;
