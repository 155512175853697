import React from "react";
import {
  CardBody,
  CardButton,
  CardFieldset,
  CardHeader,
  CardHeading,
  CardWrapper,
  CardDelete,
} from "../../../styles/common/Card";
import { useHistory } from "react-router-dom";
import { deleteTeacher } from "../../../faunadb/queries/teacher";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import {useMutation as useReactMutation, useQueryClient} from "react-query";
import { useFaunaContext } from "../../../providers/FaunaProvider";
const TeacherCard = ({ teacher }) => {
  const history = useHistory();
  const {client} = useFaunaContext();
  const queryClient = useQueryClient();
  const deleteTeacherMutation = useReactMutation(id=>deleteTeacher(client,id),{
    onSuccess:()=>{
      queryClient.invalidateQueries("allTeachers");
    }
  })
  return (
    <>
      <CardWrapper>
        <CardHeader>
          <CardHeading>{teacher?.data?.name}</CardHeading>
          {!deleteTeacherMutation.isLoading && (
            <CardDelete
              onClick={() => {
                Swal.fire({
                  title: "Do you want to delete this teacher?",
                  showCancelButton: true,
                  confirmButtonText: "Delete",
                  denyButtonText: `Don't save`,
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    // updateTeacher({
                    //   variables: { ...teacher, id: teacher.ref.value.id, deleted: true },
                    // });
                    deleteTeacherMutation.mutate(teacher.ref.value.id);
                  }
                });
              }}
            >
              <RiDeleteBin6Line />
            </CardDelete>
          )}
          <CardFieldset>{teacher?.data?.email}</CardFieldset>
        </CardHeader>
        {/* <CardBody>
          <CardFieldset>
            <CardButton
              type="button"
              onClick={() => history.push(`/teacher/${teacher?.ref?.value?.id}`)}
            >
              View teacher
            </CardButton>
          </CardFieldset>
        </CardBody> */}
      </CardWrapper>
    </>
  );
};

export default TeacherCard;
