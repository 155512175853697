
import DashboardCard from '../../components/card/DashboardCard';
import InsertStudentToPerformanceForm from './components/insert-student-to-peroformance-form';
import Detail from './components/performance-detail';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PerformanceStudentList from './components/performance-student-list';

export default function PerformanceDetail() {
    const history = useHistory();
    return (
        <>
            <DashboardCard title={'Performance Detail'}>
                <Detail></Detail>
            </DashboardCard>
            <DashboardCard title={'Students'}>
                <InsertStudentToPerformanceForm />
                <PerformanceStudentList />
            </DashboardCard>
        </>
    );
}
