/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useFaunaContext } from '../../providers/FaunaProvider';
import LoggingList from './components/LoggingList';
import Toggle from 'react-toggle';
import { Button } from 'rsuite';

const Logging = ({ type, id }) => {
  const [logs, setLogs] = useState([]);
  const [size] = useState(50);
  const [before, setBefore] = useState(null);
  const [after, setAfter] = useState(null);
  const [realTimeMode, setRealTimeMode] = useState(false);
  const { queries, client } = useFaunaContext();
  const get_logs = (previous, next) => {
    if (type && id) {
      queries.log
        .get_my_latest_logs(
          client,
          type,
          id,
          size,
          previous ? before : null,
          next ? after : null
        )
        .then((response) => {
          if (response?.data?.length > 0) {
            setLogs(response.data);
          }
          if (response?.after?.length > 0) {
            setAfter(response.after[0]);
          } else {
            setAfter(null);
          }
          if (response?.before?.length > 0) {
            setBefore(response.before[0]);
          } else {
            setBefore(null);
          }
        });
    }
    if (!type && !id) {
      queries.log
        .get_system_logs(
          client,
          size,
          previous ? before : null,
          next ? after : null
        )
        .then((response) => {
          if (response?.data?.length > 0) {
            setLogs(response.data);
          }
          if (response?.after?.length > 0) {
            setAfter(response.after[0]);
          } else {
            setAfter(null);
          }
          if (response?.before?.length > 0) {
            setBefore(response.before[0]);
          } else {
            setBefore(null);
          }
        });
    }
  };
  useEffect(() => {
    get_logs();
  }, []);

  useEffect(() => {
    if (realTimeMode) {
      const loopId = setInterval(() => {
        get_logs();
      }, 1000);
      return () => clearInterval(loopId);
    }
  }, [realTimeMode]);
  return (
    <>
      <div
        style={{
          color: 'white',
          marginTop: '1em',
        }}
      >
        Real Time Mode:
        <Toggle
          defaultChecked={realTimeMode}
          onChange={() => setRealTimeMode(!realTimeMode)}
          value={String(realTimeMode)}
        />
      </div>
      <LoggingList logs={logs} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '16px',
        }}
      >
        <Button onClick={() => get_logs(true, null)}>Pre</Button>
        <Button onClick={() => get_logs(null, true)}>Next</Button>
      </div>
    </>
  );
};

export default Logging;
