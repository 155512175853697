import moment from 'moment';
import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

const getShowText = (fromOrTo) => {
  const collectionType = fromOrTo.ref.value.collection.value.id;
  if (collectionType === 'Teacher' || collectionType === 'Student') {
    if (fromOrTo?.data?.phone_number) {
      return (
        <a
          style={{ color: 'white' }}
          href={`/${collectionType.toLowerCase()}/${fromOrTo.ref.value.id}`}
        >
          {collectionType} {fromOrTo.data.phone_number}
        </a>
      );
    } else if (fromOrTo?.data?.name) {
      return (
        <a
          style={{ color: 'white' }}
          href={`/${collectionType.toLowerCase()}/${fromOrTo.ref.value.id}`}
        >
          {collectionType} {fromOrTo.data.name}
        </a>
      );
    }
    return (
      <a
        style={{ color: 'white' }}
        href={`/${collectionType.toLowerCase()}/${fromOrTo.ref.value.id}`}
      >
        {collectionType} {fromOrTo.data.email}
      </a>
    );
  }

  if (collectionType === 'Course') {
    return (
      <a style={{ color: 'white' }} href={`/course/${fromOrTo.ref.value.id}`}>
        {collectionType} {fromOrTo.data.name}
      </a>
    );
  }

  if (collectionType === 'Class') {
    return (
      <a style={{ color: 'white' }} href={`/class/${fromOrTo.ref.value.id}`}>
        {moment(fromOrTo?.data?.date.value).format('llll')}
        <br></br>
        {collectionType} {fromOrTo?.toCourse?.name}
      </a>
    );
  }

  if (collectionType === 'Performance') {
    return (
      <a style={{ color: 'white' }} href={`/performance-detail/${fromOrTo.ref.value.id}`}>
        {collectionType} {fromOrTo?.data?.performance_name}
      </a>
    );
  }
};

const flattenLog = ({ data }) => {
  if (data.toCourse) {
    data.to.toCourse = data.toCourse;
  }
  return {
    action: data.action,
    date: moment(data.data.value).toLocaleString().substring(0, 24),
    from: getShowText(data.from),
    to: getShowText(data.to),
  };
};

const LoggingList = ({ logs }) => {
  return (
    <>
      <Table
        style={{
          color: 'white',
        }}
      >
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>From</Th>
            <Th>Action</Th>
            <Th>To</Th>
          </Tr>
        </Thead>
        <Tbody>
          {logs.map((log) => {
            let logObject = flattenLog(log);
            return (
              <Tr style={{ border: 'solid' }} key={`${logObject.date}`}>
                <Td
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {logObject.date}
                </Td>

                <Td
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {logObject.from}
                </Td>

                <Td
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {logObject.action}
                </Td>

                <Td
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {logObject.to}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default LoggingList;
