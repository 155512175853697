import styled from "styled-components";

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  margin: 0px 75px;
  height: 100%;
  width: calc(100% - 75px);
  padding: 50px;

  @media (max-width: 576px) {
    margin: unset;
    width: 100%;
  }
`;
